import i18n from 'i18next';

import { IColumn } from '../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../ui/custom-table/interface/interface.custom';
import { GetAccessProps } from '../../../interface';

interface IGetColumnsAccount {
  /**
   * Изменения статуса счета
   * @param row
   */
  onToggleSwitch({ row }: ISingleRow): Promise<boolean>;

  /**
   *  Проверяем доступность к методам
   */
  checkPermission<T>({accessPoint}:Pick<GetAccessProps, 'accessPoint'>): T;
}

export const getColumns = ({ onToggleSwitch, checkPermission }: IGetColumnsAccount): IColumn[] => {

  return [
    {
      field: 'collapse',
      label: '',
      formatHeader: 'empty',
      cell: { format: 'collapse', type: 'accounts' },
      align: 'right',
      width: 10
    },
    {
      field: 'accountId',
      label: i18n.t('module.accounts.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 80
    },
    {
      field: 'remark',
      label: i18n.t('module.accounts.titleRemark'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      sortable: true,
    },
    {
      field: 'totalBalance',
      label: i18n.t('module.accounts.titleRest'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100
    },
    {
      field: 'totalBalanceWithoutHold',
      label: i18n.t('module.accounts.titleTotalBalanceWithoutHold'),
      hint: i18n.t('module.accounts.titleTotalBalanceWithoutHoldHint'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100
    },
    {
      field: 'overdraft',
      label: i18n.t('module.accounts.titleOverdraft'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100
    },
    {
      field: 'active',
      label: i18n.t('module.common.table.active'),
      formatHeader: 'default',
      cell: {
        format: checkPermission<boolean>({ accessPoint: 'accounts.access.status.isAllow' }) ? 'popup-status' : 'boolean',
        onToggleSwitch,
        switchTooltip: [i18n.t('module.common.filter.titleIsActive'), i18n.t('module.common.filter.titleIsBlock')],
      },
      align: 'center',
      alignData: 'right',
      sortable: true,
      width: 150
    },
    {
      field: 'btnAccount',
      label: i18n.t('module.accounts.titleCards'),
      formatHeader: 'default',
      cell: { format: checkPermission<boolean>({ accessPoint: 'accounts.access.cards.isAllow' }) ? 'link-account' : 'link-account-readonly', label: i18n.t('module.accounts.titleCards') },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100
    },
  ];
};
