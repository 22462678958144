import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import i18next from 'i18next';

export const getColumns = (  ): IColumn[] => {
	return [
		{
			field: 'purseId',
			label: i18next.t('module.setting.users.titleAccountId' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'remark',
			label: i18next.t('module.setting.users.titleAccountRemark' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'amount',
			label: i18next.t('module.common.filter.titleBalance' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		}
	]
}
