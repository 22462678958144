import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { HolderGroupTitle, InnerContainer } from '../../../common';
import { useTranslation } from 'react-i18next';
import { useCheckPermission, useMediaQuery } from '../../../../hooks';
import { pursesListService, cardInfoService, limitListService } from '../../../../services';
import { CardInfo, CardLimit, CardPhone, CardPin, CardSchedule } from './';
import ReplyIcon from '@mui/icons-material/Reply';

export default function CardSetting() {
  /* Получаем языковую локаль */
  const { t } = useTranslation();
  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  /* Инициализация медиа  */
  const matches = useMediaQuery();

  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  /* Получаем карту */
  const {
    data: dataCards = { cards: [] },
    isLoading: isLoadingCards,
  } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });


  /* Получаем все доступные лимиты карт */
  const { data: cardTypeLimits = { types: [] } } = limitListService.useGetLimitsQuery({});
  /* Получаем все доступные кошельки карт */
  const { data: cardPurses = { purses: [] } } = pursesListService.useGetPursesQuery({});


  const handleClose = () => {
    // navigate(`/holder/${params.holderId}/account/${params.currentAccount}/cards?from=${searchParams.get('from')}&page=${searchParams.get('page')}&filter=${searchParams.get('filter')}`, { replace: true });
  };

  const { checkPermission } = useCheckPermission();
  /* Получаем доступность */
  const isAllowChangePin = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'cards.access.setting.changePin' });
  }, [checkPermission]);

  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
  };

  return <>
    <InnerContainer direction={'left'} show={true}>

      <Grid container style={{ flexDirection: 'column' }}>
        <Grid display={'flex'} alignItems={'center'}>
          <IconButton sx={{ marginRight: '1rem' }} onClick={handleBack}>
            <ReplyIcon />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <HolderGroupTitle isLoading={isLoadingCards}
                              mt={1}
                              title={`${t('module.cards.titleID')}: ${dataCards.cards[0]?.cardId}`}
                              sx={{ marginLeft: `${matches ? '1rem' : '0'}` }} />
            {
              !isLoadingCards && !dataCards.cards[0]?.hasLimits &&
              <Typography fontSize={'.9rem'} color={'red'}
                          fontWeight={'bold'}>{t('module.cards.tooltipLabelWarning')}</Typography>
            }
          </Box>
        </Grid>
        <Grid item xs={12} display={'flex'} flexWrap={matches ? 'wrap' : 'nowrap'}>
          <Grid>
            <CardInfo />
            <Grid item xs={12} lg={isAllowChangePin ? 11 : 6} display={'flex'} flexWrap={matches ? 'wrap' : 'nowrap'}
                  alignItems={'center'}
                  justifyContent={'space-between'}>
              <CardPhone />
              {
                isAllowChangePin && <CardPin />
              }
            </Grid>
          </Grid>
          <CardSchedule />
        </Grid>
        <CardLimit />
        { /*
         <CardInfo card={card as ICards} cards={dataCards.cards} accountId={pAccountId}
         statuses={cardStatuses.statuses}
         holderId={pHolderId} />
         */}
        <Grid item xs={12} lg={9} md={11}>
          { /*
           <CardLimits card={card as ICards} cards={dataCards.cards} cardPurses={cardPurses.purses}
           cardTypeLimits={cardTypeLimits.types} />
           */}
        </Grid>
      </Grid>

    </InnerContainer>
  </>;
}
