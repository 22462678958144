import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box } from '@mui/material';

type IInputFilled = TextFieldProps & {
  placeholder: string;
}

export default function InputNumber({ placeholder, onChange, ...other }: IInputFilled) {

  return (<Box sx={{
    paddingLeft: '.5rem',
    '& .MuiOutlinedInput-input': { textAlign: 'right', lineHeight: '2.5rem' },
    '& .MuiOutlinedInput-root': { height: '45px' },
    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  }}>
    <TextField
      onChange={onChange}
      placeholder={placeholder}
      style={{ borderRadius: '5px' }}
      {...other}
    />
  </Box>);
}
