import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const ContainerStep = styled( Box )( () => ( {
	width: "100%",
	marginTop: "1rem"
} ) );

export const ContainerStepItem = styled( Box )( ( props ) => ( {
	position: "relative",
	display: "flex",
	justifyContent: props.property ? JSON.parse( props.property ).justifyContent : "center",
	alignItems: "center",
	margin: "1rem 0",
	padding: "2rem",
	border: "1px dashed  #ccc",
	borderRadius: "10px",
	"&:hover": {
		cursor: "pointer",
		color: props.theme.palette.secondary.main,
		borderColor: props.theme.palette.primary.main
	}
} ) )
