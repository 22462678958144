import { GridProps } from '@mui/material/Grid/Grid';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LBottomItem = styled((props: GridProps) => (
  <Grid item {...props} />))(({ theme }) => ({
  width: '240px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'center',
  [theme.breakpoints.down('md')]: {
    width: 'inherit',
  },
}));
