import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import i18next from 'i18next';

export const getColumns = ( ): IColumn[] => {
	return [
		{
			field: 'accountId',
			label: i18next.t('module.setting.users.titleAccountId' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'accountRemark',
			label: i18next.t('module.setting.users.titleAccountRemark' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'lastName',
			label: i18next.t('module.setting.users.titleLastName' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'firstName',
			label: i18next.t('module.setting.users.titleFirstName' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'cardId',
			label: i18next.t('module.cards.titleID' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'mobile',
			label: i18next.t('module.cards.titlePhone' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		}
	]
}
