import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { InputSearchLine, Modal, CustomTable } from '../../../../../ui';
import { getColumns } from '../../card.columns';
import { initialAccount, ITransferAccount } from '../../../interface';
import { IRow } from '../../../../../ui/custom-table/components/Rows/Rows';
import { filterObject } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';
import { useActiveHolder } from '../../../../../../hooks';
import { accountsService, cardListService } from '../../../../../../services';

/* Кол-во строк в таблице */
const countPerOnPage: number = 10;//+COUNT_PER_ON_PAGE;

interface IModalFilterAccount {
  isShow: boolean;

  handleApplyAccount(account: ITransferAccount): void;

  handleClose(): void;
}

export default function ModalFilterAccount({
                                             isShow,
                                             handleApplyAccount,
                                             handleClose,
                                           }: IModalFilterAccount) {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();

  const childRef = useRef<any>(null);

  useEffect(() => {
    if (childRef.current) childRef.current.focus();
  }, [childRef.current]);

  /* Строка поиска по всем полям таблицы */
  const [searchStr, setSearchStr] = useState('');
  const [selectedAccount, setSelectedAccount] = useState<ITransferAccount>(initialAccount);

  const {
    data: dataCards = { cards: [] },
    isLoading,
  } = cardListService.useGetCardListQuery({ holderId: activeHolderId, accountId: 0 });

  const { data: dataAccounts = { accounts: [] } } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const handleBtnOk = () => {
    selectedAccount.accountId && handleApplyAccount(selectedAccount);
    handleClose();
  };

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);

  const cbChangePage = (page: number) => {
    setPage(page - 1);
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    const emptyAccounts = dataAccounts.accounts.filter(acc => acc.numbersActiveCards === 0).map(a => ({
      ...a,
      accountRemark: a.remark,
    }));

    let filteredArr = [...dataCards.cards, ...emptyAccounts];
    filteredArr = filterObject({
      arr: filteredArr,
      fields: ['accountId', 'accountRemark', 'lastName', 'firstName', 'mobile', 'cardId'],
      searchStr,
    });
    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);
    const count = searchStr !== '' ? filteredArr.length : (dataCards.cards.length + emptyAccounts.length);


    return { arr: filteredArr, count };
  }, [dataCards, dataAccounts, searchStr, pageNum]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const handleClickRow = (row: IRow) => {

    const totalBalance = dataAccounts.accounts.filter(account => account.accountId === row.accountId)[0].totalBalanceWithoutOverdraft;

    setSelectedAccount({
      accountId: row.accountId,
      accountRemark: row.accountRemark,
      totalBalance: totalBalance,
    });
  };


  return (
    <Modal
      handleBtnOk={handleBtnOk}
      isOpen={isShow}
      handleBtnCancel={handleClose}
      disabled={!selectedAccount.accountId}

    >
      <Grid item sx={{
        width: '850px', height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}>
        <Grid sx={{ marginBottom: '2rem' }} display={'flex'} alignItems={'center'}
              justifyContent={'space-between'}>
          <InputSearchLine
            ref={ref => childRef.current = ref}
            placeholder={t('module.common.title.titleSearchStr')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPage(0);
              setSearchStr(e.currentTarget.value);
            }}
            style={{ borderRadius: '5px' }}
          />
          <Typography>{t('module.common.title.titleSelected')}: {selectedAccount.accountId || ' - '}</Typography>
        </Grid>
        <Grid>
          {
            <CustomTable
              selectedRow
              rows={rows.arr}
              count={rows.count}
              columns={columns}
              isLoading={isLoading}
              labelEmptyRows={t('module.common.table.emptyRows')}
              rowsPerPageOptions={[countPerOnPage]}
              cbChangePage={cbChangePage}
              pageNum={pageNum}
              onClick={handleClickRow}
              sx={{ height: '391px' }}
            />
          }
        </Grid>
      </Grid>
    </Modal>

  );
}
