import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';

import './styles/main.css';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useProfile } from './hooks';
import { saveActiveRole, setAuthorize } from './store';
import { getToken } from './utils';
import { theme } from './styles';
import { Credentials, MainLayout } from './components/layout';
import { ModalContainer } from './components/ui';
import { ukUA } from '@mui/material/locale';

function App() {
  const { isAuth } = useAppSelector(state => state.layoutReducer);

  const profile = useProfile();
  const navigation = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const token = getToken();
    /* Если состояние false, токена не существует и адресная строка не /login перебрасіваем туда */
    if (!isAuth && !token && location.pathname !== '/login') {
      navigation('/login', { replace: true });
    }
  }, [isAuth]);

  useEffect(() => {
    const token = getToken();
    token && dispatch(setAuthorize({ isAuth: true }));

    /* Если пользователь не авторизован, и не пытается зарегистрироваться/восстановить пароль тогда переходим к логину */
    const url: string = params['*'] || '';
    const keywords = ['register/', 'forgot-password/'];

    if (!token && !keywords.some(keyword => url.includes(keyword))) {
      navigation('/login', { replace: true });
    }

    /* Устанавливаем активную роль */
    //dispatch(saveActiveRole({ role: data.role }));

  }, []);

  const customTheme = useMemo(() => { return createTheme({ ...profile }, ukUA); }, [profile, ukUA]);

  const renderLayout = useMemo(() => {
    return !isAuth
      ? <Credentials />
      : <MainLayout />;
  }, [isAuth]);

  return (
    <ThemeProvider theme={customTheme ? customTheme : theme}>
      <ModalContainer />
      {renderLayout}
    </ThemeProvider>
  );
}

export default App;
