const translation = {
  title: 'SmartCards CS',
  config: {
    locale: {
      auth: {
        enterCRM: 'Увійти в CRM',
      },
    },
  },
  modal: {
    confirmExit: 'Ви дійсно бажаєте вийти з системи?',
    labelOk: 'Так',
    labelCancel: 'Відміна',
    labelAdd: 'Додати',
    labelEdit: 'Редагувати',
  },
  menu: {
    profile: {
      title: 'Компанія',
    },
    account: {
      title: 'Рахунки',
    },
    cards: {
      title: 'Карти',
    },
    reports: {
      title: 'Звіти',
    },
    documents: {
      title: 'Документи',
    },
    exchange: {
      title: 'Переказ коштів',
    },
    myCard: {
      title: 'Моя картка',
    },
    helpers: {
      title: 'Інструкції',
    },
    setting: {
      title: 'Налаштування',
    },
    users: {
      title: 'Користувачі',
    },
  },
  module: {
    common: {
      table: {
        num: '#',
        active: 'Статус',
        filter: 'Фільтр',
        clearFilter: 'Очистити фильтр',
        emptyRows: 'Рядки відсутні',
        setting: 'Налаштування',
      },
      filter: {
        title: 'Фільтр',
        titleSubmit: 'Застосувати',
        titleDate: 'Дата',
        titleStatus: 'Статус',
        titleIsActive: 'Активний',
        titleInactive: 'Не активний',
        titleIsBlock: 'Заблоковано',
        titleBalance: 'Баланс',
        titleNumCard: 'Номер карти',
        titlePhone: 'Телефон',
        titleVIN: 'Авто',
        titleFirstName: 'Ім\'я',
        titleLastName: 'Прізвище',
        titleFrom: 'Від',
        titleTo: 'До',
        titleAccountName: 'Назва',
        titleSelectAll: 'Всі',
        titleNotSelected: 'Не обрано',
        titleNotAccess: 'Доступ заборонено',
        rowNotSelected: 'Не обрано жодну строку',
      },
      title: {
        titleOnlyCurrentHolder: 'Тільки поточна компанія',
        backToClients: 'Назад до списку клієнтів',
        backToCards: 'Назад до списку карт',
        backToAccounts: 'Назад до списку рахунків',
        titleClientComboBox: 'Клієнт',
        titleAccountComboBox: 'Рахунок',
        titleCards: 'Карти',
        titleDataNotFound: 'Дані не знайдені',
        titleNotInitialized: 'Не визначено',
        titleOk: 'Так',
        titleCancel: 'Відміна',
        titlePeriod: 'Період',
        titleDocumentation: 'Документація',
        titleLinks: 'Посилання',
        titleSearchStr: 'Пошукова строка',
        titleSelected: 'Обрано',
        titleNext: 'Далі',
        titleBack: 'Назад',
        titleCommit: 'Виконати',
        titleSum: 'Сума',
        titleOr: 'Або',
        titleCreate: 'Створити',
        titleDelete: 'Видалити',
        titleDeletePhone: 'Видалити номер?',
        titleOf: 'з',
        titleExecute: 'Відобразити',
        titleSubmit: 'Зберегти',
        titleReportTooltip: 'Відкрити календар',
        titleReportLabelCancel: 'Закрити',
        titleReportLabelInput: 'Період',
        titleReportLabelBtn: 'Обрати',
        dateFrom: 'Період з',
        dateTo: 'Період по',
      },
      modal: {
        type: {
          info: 'Інформація',
          success: 'Успішне виконання',
          warning: 'Попередження',
          error: 'Помилка',
          confirmation: 'Підтвердження',
          default: '',
        },
        labelConfirmation: 'Ви дійсно бажаєте видалити строку?',
        labelConfirmationDeleteUser: 'Ви дійсно бажаєте видалити користувача?',
        labelConfirmationDeleteUserCurrentHolder: 'Ви дійсно бажаєте забрати доступ у користувача по поточній фірмі?',
      },
    },
    register: {
      registerCRM: 'Реєстрація',
      updateCRM: 'Зміна паролю',
      welcomeTextPhone: 'Будь-ласка, введіть Ваш номер телефону',
      welcomeTextOTP: 'Введіть код, який Вам було відправлено в СМС на вказаний номер телефону',
      titlePhone: 'Телефон',
      titleOTP: 'Код з СМС',
      titleForPass: 'Задайте ваш новий пароль до кабінету',
      btnRegisterPhone: 'Відправити СМС-код',
      btnRegisterOTP: 'Зареєструватись',
      btnUpdatePassOTP: 'Оновити дані',
      titleEnterPass: 'Введіть пароль',
      titleEnterRePass: 'Повтор паролю',
      titleRegisterDriver: 'Ваш номер телефону "{{urlParam.phone}}", буде прив`язаний до карти "{{urlParam.cardId}}". Підтверджуєте цю дію?',
      titleRegisterDriverSuccess: 'Номер телефону "{{urlParam.phone}}", успішно прив`язаний до карти "{{urlParam.cardId}}". Далі вам необхідно пройти реєстарцію.',
    },
    login: {
      enterCRM: 'Увійти в CRM',
      welcomeText: 'Щоб увійти в особистий кабінет, введіть номер телефону та пароль або зареєструйтесь',
      titleLogin: 'Телефон',
      titlePassword: 'Пароль',
      btnRegister: 'Реєстрація',
      btnForgotPass: 'Забули пароль',
      btnEnter: 'Увійти',
      btnResendOTP: 'Відправити смс з новим кодом',
      btnLogout: 'Вийти',
    },
    clientList: {
      placeholderSearchString: 'Пошук по назві або ЄДРПОУ',
      titleAccounts: 'Рахунки',
      titlePage: 'Клієнти',
      titleID: 'ID',
      titleNameClient: 'Назва клієнта',
      titleOKPO: 'ЄДРПОУ',
      titleINN: 'ІПН',
      titlePhone: 'Телефон',
      titleEmail: 'Пошта',
      titleAddress: 'Адреса',
      titleLegalAddress: 'Юридична адреса',
      titleNumContract: 'Номер договору',
      titleStatus: 'Статус клієнта',
    },
    holders: {
      btnSelectHolder: 'Обрати',
    },
    accounts: {
      titleID: 'ID',
      titleRemark: 'Опис',
      titleRest: 'Залишок',
      titleOverdraft: 'Овердрафт',
      titleTotalBalance: 'Доступно',
      titleTotalBalanceHint: 'Баланс рахунку',
      titlePurses: 'Гаманці',
      titleTotalBalanceWithoutHold: 'Баланс',
      titleTotalBalanceWithoutHoldHint: 'Баланс рахунку без урахування холдів',
      titleCards: 'Карти',
      btnAccountLink: 'Перейти до рахунків',
    },
    cards: {
      titleID: 'Карта',
      titleVin: 'Авто',
      titlePhone: 'Телефон',
      titleName: 'Прізвище, ім\'я',
      titleActiveMobileApp: 'Кабінет водія',
      titleSetting: 'Налаштування',
      titleConfirmRenewPin: 'Ви дійсно бажаєте змінити поточний pin?',
      titleShowNewPin: 'Новий PIN',
      titleBtnSendOtp: 'Відправити код',
      titleBtnRetrySendOtp: 'Відправити код повторно',
      titleBtnWithoutConfirm: 'Зберегти без підтверждення',
      titleCodeFromSMS: 'Код з смс',
      titleBtnCommit: 'Підтвердити зміну номеру',
      titlePermitByDayOfTheWeek: 'Дозвіл по дням тижня',
      tooltipNoLimit: 'Відсутній ліміт',
      tooltipLabelWarning: 'Увага! Ліміт не встановлено, карта не зможе обслуговуватись.',
      limits: {
        title: 'Ліміти',
        titleBtnAddLimit: 'Додати ліміт',
        titleMoneySchema: 'Гривнева схема',
        titleQtySchema: 'Літрова схема',
        titleShortMoneySchema: 'Гривнева',
        titleShortQtySchema: 'Літрова',
        titlePurses: 'Гаманці',
        titleSchemaType: 'Схема',
        titleType: 'Тип',
        titleMoneyByDay: 'Грн/Доба',
        titleQtyByDay: 'Літри/Доба',
        titleMoneyByMonth: 'Грн/Місяць',
        titleQtyByMonth: 'Літри/Місяць',
        titleMoneyByOnce: 'Грн/Разово',
        titleQtyByOnce: 'Літри/Разово',
        titleMoneyByWeek: 'Грн/Тиждень',
        titleQtyByWeek: 'Літри/Тиждень',
        titleMoneyOutOfTime: 'Грн.до списання',
        titleQtyOutOfTime: 'Літри до списання',
        titleBalance: 'Обіг',
        titleLimitAmount: 'Ліміт',
        titleRest: 'Залишок',
        newLimit: {
          title: 'Рахунок',
          titleIndividual: 'Індивідуальний',
          titleCommon: 'Загальний',
          titleTypeLimit: 'Тип ліміту',
          titleLimitAmount: 'Сума',
        },
      },
      qr: {
        notExistCard: 'Картка відсутня.\n Щоб додати картку, прив\'яжіть свій номер телефона до карти',
        notMatchResolution: 'Не підходить роздільна здатність екрану, будь ласка, поверніть телефон',
        contextMenu: {
          blocked: 'Блокування',
          confirmBlockedCard: 'Ви дійсно бажаєте заблокувати карту?',
          limits: 'Перегляд лімітів',
        },
        status: {
          '0': 'Заблокована',
          '1': 'Активна',
        },
      },
    },
    transfer: {
      titleAccountSource: 'Звідки',
      titleAccountSourceHelper: 'Оберіть звідки',
      titleAccountDestination: 'Куди',
      titleAccountDestinationHelper: 'Оберіть куди',
      titleTransferButton: 'Виконати переказ',
      confirmTransfer: 'Ви дійсно бажаєте виконати цей переказ?',
    },
    reports: {
      titleTrnKind: 'Вид звіту',
      titleDate: 'Дата',
      titleCard: 'Карта',
      titleNameAZS: 'АЗС',
      titleAddress: 'Адреса АЗС',
      titlePurse: 'Гаманець',
      titleQty: 'Кіл-ть',
      titlePrice: 'Ціна',
      titleSum: 'Сума',
      titleDiscount: 'Знижка',
      titleSumWithDiscount: 'Загалом',
      titleName: 'Назва',
      titleUseFromDate: 'Від дати',
      titleUseToDate: 'До дати',
      titleSubmitXLS: 'Зберегти EXCEL',
      tittleFilterClear: 'Очистити',
      titleAll: 'Всі',
      titleLastTrn: 'Останні транзакції',
      titleFilterPurses: 'Гаманці',
    },
    documents: {
      billing: {
        titleTab: 'Рахунки',
        fileName: 'Рахунок_від_%1%',
        typeFuel: 'Оплата за нафтопродукти',
        typeGoods: 'Оплата за товар',
        titleBtnCreateInvoice: 'Сформувати рахунок',
        titleLoadingFile: 'Формування рахунка...',
        titleLoadingFileError: 'Помилка відображення предперегляду...',
        titleBtnSaveXLSX: 'Зберегти XLSX',
        titleBtnSavePDF: 'Зберегти PDF',
        titleTypeGoods: 'Оплата за товари та послуги',
        shortTitleTypeGoods: 'товар',
        titleTypeFuel: 'Оплата за нафтопродукти',
        shortTitleTypeFuel: 'Н/П',
      },
      certificates: {
        titleTab: 'Видаткові документи',
        table: {
          type: 'Тип документу',
          number: 'Номер документу',
          qty: 'Кількість',
          sumWithPDV: 'Сума, грн з ПДВ',
          status: 'Статус',
        },
      },
    },
    setting: {
      titleChangePass: 'Змінити пароль',
      titleChangeProfile: 'Профіль',
      titleBtnChangeProfile: 'Змінити дані',
      titleAddNewUser: 'Додати нового користувача',
      users: {
        titleAccountId: 'ID',
        titleAccountRemark: 'Опис',
        titleFio: 'Прізвище, ім\'я',
        titleFirstName: 'Ім\'я',
        titleLastName: 'Прізвище',
        titlePhone: 'Телефон',
        titleEmail: 'Email',
        titleApiRole: 'Роль',
        titleActive: 'Активність',
        titleHoldersLength: 'Клієнти кіл-ть',
        titleHolderDefaultShort: 'Осн.компанія',
        titleHolderDefault: 'Основна компанія користувача',
        titleUserEdit: 'Дозвіл на зміну профіля',
        confirmChangeAdminRole: 'Увага! Операція по зміні доступу не зворотня і може призвести до його втрати. Ви впевнені?'
      },
      api: {
        titleApi: 'Для доступу до API використовуйте створений API key:',
      },
      user: {
        limitAccounts: 'Обмеження рахунків',
        limitCards: 'Обмеження карт',
      },
    },
    dataGroup: {
      infoClient: 'Інформація клієнта',
      accounts: 'Рахунки',
      analytics: 'Аналітика',
      limits: 'Залишки лімітів',
      addLimits: 'Додати ліміт',
      additionalInfo: 'Додаткова інформація',
      analyticInfo: 'Аналітика',
    },
  },
  roles: {
    SmartCardsAdmin: 'Адміністратор',
    SmartCardsManager: 'Менеджер',
    SmartCardsViewer: 'Перегляд',
    SmartCardsReporter: 'Звіти',
    SmartCardsDriver: 'Водій',
  },
  statusClient: {
    '0': 'Активний',
    '1': 'Заблокований',
    '2': 'Видалений',
    '3': 'Переміщений',
  },
  statusCard: {
    '0': 'Активна',
    '1': 'Заблокована',
    '2': 'Архівна',
  },
  error: {
    '1': 'Введені дані не є номером телефону',
    '2': 'Номер телефону не знайдено',
    '3': 'Не корректний код з СМС',
    '4': 'Користувач був зареєстрований раніше',
    '5': 'Користувач не знайдений',
    '6': 'Невірний пароль або номер телефону (email)',
    '7': 'Немає доступу',
    '8': 'Помилкові параметри',
    '9': 'Помилка під час створення ліміту',
    '10': 'Не вдалось встановити новий пин',
    '11': 'Користувач був видален, зверніться до адміністратора',
    '12': 'Користувач був деактивован, зверніться до адміністратора',
    '18': 'Помилка оновлення ліміту',
    '101': 'Рахунок не належить клієнту',
    '102': 'Недостатньо коштів',
    '401': 'Помилка авторизації',
    '403': 'Доступ відсутній',
    '404': 'URL адреса запиту не знайдено',
    '1000': 'Невідома помилка',
    '1001': 'Користувач не авторизован',
    '1002': 'Операція виконана успішно',
    '1003': 'Під час виконання сталася помилка',
    '1004': 'Не коректний номер телефону',
    '1005': 'Помилка запиту',
    '1006': 'Запит не авторизован',
    '1007': 'Заповнене поле не вірного формату',
    '1008': 'Обрано обмеження по рахункам, але рахунки не обрані',
    '1009': 'Обрано обмеження по картам, але карти не обрані',
    '1100': 'Авторизаційні дані не заповнені',

    ERROR_GET_ACTIVE_HOLDER_ID: 'Помилка при отриманні активного клиента, необхїдно вийти і зайти в систему заново',
    SEND_OTP_SUCCESS: 'Код був успішно відправлений на номер {{phone}}',
    PASSWORD_CHANGED: 'Пароль змінено',
    REGISTER_SUCCESSFULLY: 'Коричстувая зареєстрован',
    TOO_MANY_REQUEST: 'Дуже багато запитів, спробуйте трохи пізніше',
    REQUIRED_FIELD: 'Обов\'язкове поле',
    CONFIG_ERROR: 'Помилка отримання конфигураційного файлу',
    FETCH_ERROR: 'Помилка мережі',
    PARSING_ERROR: 'Помилка при формуванні запиту',
    UNKNOWN_ERROR: 'Невідома помилка',
    ERROR_CHOICE_DATE: 'Некоректна дата',
    SUCCESS: 'Вдале виконання',
  },
  validation: {
    ERROR_EMAIL: 'Некоректний email',
    ERROR_PHONE: 'Некоректний номер телефону',
    EMPTY_ONE_ACCOUNT: 'рахунок',
    EMPTY_ONE_PURSES: 'гаманець',
    EMPTY_ONE_CARDS: 'карта',
    ERROR_EMPTY_ONE_PARAM: 'Не вистачає параметрів звіту({{params}})'
  },
};
export default translation;
