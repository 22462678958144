import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomTable, InputSearchLine } from '../../../../../../ui';
import { RoleProps } from '../../../../../../../interface';
import { UserHoldersProps } from '../../interface';
import { holdersListService, roleListService, userProfileService } from '../../../../../../../services';
import { useTranslation } from 'react-i18next';
import { filterObject } from '../../../../../../../utils';
import { getColumns, SelectRolesProps } from './holder.list.columns';
import { Roles } from '../../../../../../../helpers';
import { useAppSelector } from '../../../../../../../hooks';

interface HolderListProps {
  holders: UserHoldersProps[];
  disabled?: boolean;

  onChange(holders: UserHoldersProps[], changedCriticalValue: boolean): void;
}

interface HolderItemProps {
  id: number;
  holderId: number;
  name: string;
  roleName: string;
  limitAccounts: boolean;
  limitCards: boolean;

}

const countPerOnPage: number = 7;//+COUNT_PER_ON_PAGE;
export const HolderList = memo(({ holders, onChange, disabled }: HolderListProps) => {

  const { t } = useTranslation();
  const { data: dataRoles = { roles: [] } } = roleListService.useGetRolesQuery('');
  const {
    data: dataHolders = { user: { holders: [] } },
    isLoading,
  } = userProfileService.useGetUserProfileQuery({ isEdit: true });
  //const { data: dataHolders = { holders: [] }, isLoading } = holdersListService.useGetHoldersQuery('');

  const [changedCriticalValue, setChangeCriticalValue] = useState(false);

  /* Текущее состояние списка холдеров */
  const [currentHolders, setCurrentHolder] = React.useState<HolderItemProps[]>([]);

  /* Поисковая строка холдеров */
  const [searchStr, setSearchStr] = useState('');

  const handleChangeRole = useCallback((row: HolderItemProps) => {
    /* Проверяем на существование выбранного холдера (находим его индекс) */
    const idx = holders.findIndex(holder => holder.holderId === row.holderId);
    let newHolders = [];
    if (idx !== -1) {
      newHolders = [...holders];
      newHolders[idx] = { ...newHolders[idx], apiRole: row.roleName, limitCards: false, limitAccounts: false };
      delete newHolders[idx].accessAccounts;
    } else {
      /* Если не существует добавлем */
      newHolders = [...holders, {
        holderId: row.holderId,
        name: row.name,
        apiRole: row.roleName,
        limitCards: false,
        limitAccounts: false,
      }];
    }

    setChangeCriticalValue(checkDownGradeRole(currentHolders, newHolders));

    onChange(newHolders, checkDownGradeRole(currentHolders, newHolders));
  }, [holders, currentHolders]);

  const handleChangeHolderAccount = useCallback((row: HolderItemProps) => {
    /* Проверяем на существование выбранного холдера (находим его индекс) */
    const newHolders: UserHoldersProps[] = [...holders];
    const idx = newHolders.findIndex(holder => holder.holderId === row.holderId);
    /* Проверяем активировали ли ограничение по счетам */
    if (row.limitAccounts) {
      newHolders[idx] = { ...newHolders[idx], limitAccounts: row.limitAccounts || false, accessAccounts: [] };
    } else {
      /* Если активацию сняли удаляем массив accessAccounts вместе с переменной*/
      // eslint-disable-next-line no-prototype-builtins
      if (newHolders[idx].hasOwnProperty('accessAccounts')) {
        const { accessAccounts, ...withOutAccessAccounts } = newHolders[idx];
        newHolders[idx] = withOutAccessAccounts;
      }
      //delete newHolders[idx].accessAccounts;
      newHolders[idx] = { ...newHolders[idx], limitCards: false, limitAccounts: row.limitAccounts || false };
    }
    onChange(newHolders, changedCriticalValue);
  }, [holders, changedCriticalValue]);

  const handleChangeHolderCards = useCallback((row: HolderItemProps) => {
    /* Проверяем на существование выбранного холдера (находим его индекс) */
    const newHolders: UserHoldersProps[] = [...holders];
    const idx = newHolders.findIndex(holder => holder.holderId === row.holderId);
    /* Проверяем активировали ли ограничение по счетам */
    newHolders[idx] = { ...newHolders[idx], limitCards: row.limitCards || false, accessAccounts: [] };
    onChange(newHolders, changedCriticalValue);
  }, [holders, changedCriticalValue]);

  const checkDownGradeRole = (currentHolders: HolderItemProps[], newHolders: UserHoldersProps[]): boolean => {
    const isExistDowngradeRole = currentHolders.find(cHolder => (
      cHolder.roleName === Roles.Admin && newHolders.findIndex(nHolder => nHolder.holderId === cHolder.holderId && nHolder.apiRole !== Roles.Admin) !== -1
    ));
    return !!isExistDowngradeRole;
  };

  useEffect(() => {
    const newCurrentHolder = dataHolders.user.holders.map(holder => {
      let isExistAccounts: undefined | boolean = false;
      let isExistCards = false;
      let apiRole = '-';
      /* Проверяем что объект холдеров существует */
      if (holders) {
        /* Пробуем найти холдера который есть в нашем списке */
        const userHolder = holders && holders.find(userHolder => userHolder.holderId === holder.holderId);

        if (userHolder) {
          /* Проверяем заполнен ли у него массив ограничений счетов */
          isExistAccounts = !!(userHolder.accessAccounts && (userHolder.accessAccounts.length > 0)) || !!userHolder.limitAccounts;
          /* Проверяем если есть аккаунты есть ли установленные ограничения по картам */
          if (isExistAccounts) {
            isExistCards = !!userHolder.accessAccounts?.find(account => account && account.accessCards && account.accessCards.length > 0) as boolean;
          }
          /* Если таких нет проверяем может стоит флаг установки лимитов карт */
          if (!isExistCards) {
            isExistCards = !!userHolder.limitCards;
          }
          apiRole = userHolder.apiRole || '-' as string;
        }
      }

      return ({
        id: holder.holderId,
        holderId: holder.holderId,
        name: holder.name,
        roleName: apiRole,
        limitAccounts: isExistAccounts,
        limitCards: isExistCards,
      });
    });
    setCurrentHolder([...newCurrentHolder]);
  }, [holders, dataHolders]);

  const renderRoles: SelectRolesProps[] = useMemo(() => {
    return dataRoles.roles && dataRoles.roles.map((role: RoleProps) => ({
      label: t(`roles.${role.roleName}`, 'ERROR'),
      value: role.roleName,
    }));
  }, [dataRoles, t]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);

  const cbChangePage = (page: number) => {
    setPage(page - 1);
  };

  const { config } = useAppSelector(state => state.layoutReducer);

  const columns = useMemo(() => {
    return getColumns({
      roles: renderRoles,
      handleChangeRole,
      handleChangeHolderAccount,
      handleChangeHolderCards,
      disabled,
      setting: { config } as any,
    });
  }, [renderRoles, handleChangeRole, handleChangeHolderAccount, handleChangeHolderCards, disabled, config]);

  const rows = useMemo(() => {
    let filteredArr = filterObject({ arr: currentHolders, fields: ['name'], searchStr: searchStr });


    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);
    const count = searchStr !== '' ? filteredArr.length : currentHolders.length;
    return { arr: filteredArr, count };

  }, [currentHolders, searchStr, pageNum]);

  const renderSearchCmp = useMemo(() => {

    return (<>

        <InputSearchLine
          placeholder={t('module.common.title.titleSearchStr')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchStr(e.currentTarget.value);
          }}
          style={{ borderRadius: '5px' }}
        />

      </>
    );
  }, [holders]);


  return (<>

    <CustomTable
      rows={rows.arr}
      count={rows.count}
      columns={columns}
      isLoading={isLoading}
      labelEmptyRows={t('module.common.table.emptyRows')}
      isSimple
      topPagination
      topComponent={renderSearchCmp}
      rowsPerPageOptions={[countPerOnPage]}
      cbChangePage={cbChangePage}
      pageNum={pageNum}
      sx={{ height: '100%', '& .MuiTableCell-root': { padding: 0 } }}
    />

  </>);
});
