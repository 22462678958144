import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialModalState } from './initial/initial-modal';

import { t } from 'i18next';
import { ModalReducerState } from '../../interface';


export const modalReducer = createSlice({
  name: 'modalReducer',
  initialState: initialModalState,
  reducers: {
    showModal(state: ModalReducerState, action: PayloadAction<Omit<ModalReducerState, 'isShowModal'>>) {
      return {
        ...initialModalState,
        labelOk: t('modal.labelOk'),
        labelCancel: t('modal.labelCancel'),
        ...action.payload,
        isShowModal: true,
      };
    },
    removeModal() {
      return { ...initialModalState };
    },
  },
});
