import { IBillingDoc } from '../interface.billing';
import { getPDV, getWorkBook, saveXLSFile } from '../../commonMethods';
import moment from 'moment';
import { ClientBills } from '../../client-bills/clientBills';
import { getNumberToString } from '../../../../../../utils';

export const getSocarXLSXFile = async ({
    numBill, supplier, holder, tax, shortTypeGoods, typeGoods, sum
}: IBillingDoc) => {

    const {convertedInteger, integerCurrency, fractionalString, fractionalCurrency} = getNumberToString(sum);
    const sumToString = `${convertedInteger} ${integerCurrency} ${fractionalString} ${fractionalCurrency}`;
    /**
     * ПДВ и Сумма без ПДВ
     */
    const {pdv, bezPdv} = getPDV(sum, tax);
    /**
     * ПДВ
     */
    const PDV = pdv;

    const wb = await getWorkBook(ClientBills.Socar);
    const ws = wb.worksheets[0];
    ws.pageSetup.margins = {
        left: 0.1, right: 0, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3
    };

    /**
     * Переопределяем ширину ячеек
     */
    ws.getColumn("B").width = 15;
    ws.getColumn("E").width = 10;
    ws.getColumn("F").width = 6.4;
    ws.getColumn("G").width = 10;
    ws.getColumn("H").width = 10;
    ws.getColumn("I").width = 10;
    ws.getColumn("J").width = 12;
    ws.getColumn("K").width = 9;

    /**
     * Одержувач телефон
     */
    ws.getCell(12, 3).value = holder.fullName;
    ws.getCell(13, 3).value = `тел. ${holder.phone}`;
    /**
     * Угода
     */
    ws.getCell(15, 3).value = holder.contractNumber;
    /**
     * Призначення платежу
     */
    ws.getCell(16, 3).value = `сплата за ${shortTypeGoods} зг. рах.№ ${numBill} від ${moment().format('DD.MM.YY')} &${holder.okpo}& , ПДВ ${tax}%`;
    /**
     * Номер рахунку
     */
    ws.getCell(18, 3).value = `Рахунок № ${numBill}`;
    /**
     * Від якого числа
     */
    ws.getCell(19, 7).value = `від ${moment().format('DD.MM.YY')} р.`;
    /**
     * Найменування за що
     */
    ws.getCell(22, 2).value = typeGoods;
    /**
     * Кол-во
     */
    ws.getCell(22, 7).value = sum;
    /**
     * Сума
     */
    ws.getCell(22, 10).value = sum;
    /**
     * Процентная ставка
     */
    ws.getCell(22, 11).value = `${tax}%`;
    /**
     * Разом без ПДВ
     */
    ws.getCell(23, 10).value = bezPdv;
    /**
     * ПДВ
     */
    ws.getCell(24, 10).value = PDV;
    /**
     * Разом без ПДВ
     */
    ws.getCell(25, 10).value = sum;
    /**
     * Сума прописом
     */
    ws.getCell(28, 1).value = sumToString;

    /**
     * Устанавливаем область печати
     */
    ws.pageSetup.printArea = 'A1:K29';

    await saveXLSFile(wb, numBill);
};
