import Modal from '../../../../../ui/modal/Modal';
import React, { useMemo, useState } from 'react';
import { CustomTable } from '../../../../../ui';
import { getColumns } from '../../purse.columns';
import { Typography } from '@mui/material';
import { initialPurse, ITransferAccount, ITransferPurse } from '../../../interface';
import { purseService } from '../../../../../../services';
import { useTranslation } from 'react-i18next';


interface IModalFilterPurses {
  isShow: boolean;
  account?: ITransferAccount;

  handleApplyPurse(transfer: ITransferAccount & ITransferPurse): void;

  handleClose(): void;
}

export default function ModalFilterPurses({
                                            isShow,
                                            account,
                                            handleApplyPurse,
                                            handleClose,
                                          }: IModalFilterPurses) {
  const { t } = useTranslation();
  const [selectedPurse, setPurse] = useState<ITransferPurse>(initialPurse);

  /* Получаем все доступные кошельки карт */
  const { data: cardPurses = { purses: [] }, isLoading } = purseService.useGetPursesByAccountIdQuery({
    accountId: account && account.accountId ? account.accountId : -1,
  });


  const handleBtnOk = () => {
    if (selectedPurse.purseId !== undefined) {

      handleApplyPurse({
        accountId: account?.accountId,
        accountRemark: account?.accountRemark ? account?.accountRemark : '',
        totalBalance: selectedPurse.totalBalance || 0,
        purseId: selectedPurse.purseId,
        remark: selectedPurse.remark,
      });

    }
    handleClose();
  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, [t]);

  const rows = useMemo(() => {

    const moneyPurses = {
      id: 0,
      purseId: 0,
      remark: account?.accountRemark || '',
      amount: account?.totalBalance || 0,
    };

    return [moneyPurses, ...cardPurses.purses];
  }, [account, cardPurses]);

  return (
    <Modal
      handleBtnOk={handleBtnOk}
      isOpen={isShow}
      handleBtnCancel={handleClose}
      disabled={selectedPurse.purseId === undefined}

    >
      <>
        <Typography
          sx={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}
        >
          {t('module.common.title.titleSelected')}: {selectedPurse.purseId !== undefined ? selectedPurse.purseId : ' - '}
        </Typography>
        <CustomTable
          selectedRow
          rows={rows}
          columns={columns}
          isLoading={isLoading}
          onClick={(row:any) => setPurse({ purseId: row.purseId, remark: row.remark, totalBalance: row.amount })}
          isSimple
          labelEmptyRows={t('module.common.table.emptyRows')}
        />
      </>
    </Modal>
  );
}
