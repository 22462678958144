import { ContainerStep } from '../style';
import { Typography } from '@mui/material';
import React, { Suspense, useState } from 'react';
import Loading from '../../../../ui/loading/Loading';
import ModalFilterAccount from './components/ModalFilterAccount';
import ModalFilterPurses from './components/ModalFilterPurses';
import { ITransferAccount, ITransferPurse } from '../../interface';

interface ITransferType {
  title?: string;
  label: JSX.Element;
  type: 'source' | 'destination';

  onChange({ accountId, purseId, totalBalance, accountRemark, remark }: ITransferAccount & ITransferPurse): void;
}

export default function TransferButton({ title, label, type, onChange }: ITransferType) {
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenModalPurse, setOpenModalPurse] = useState(false);

  const [account, setAccount] = useState<ITransferAccount>();

  const handleApplyAccount = (account: ITransferAccount) => {

    if (type === 'source') {
      setAccount(account);
      setOpenModalPurse(true);
      return;
    }

    if (type === 'destination') {
      onChange({
        purseId: undefined,
        remark: '',
        totalBalance: 0,
        ...account,
      });
      return;
    }

  };

  const handleClickContainer = () => {
    setOpenModal(true);
  };

  const handleApplyFilter = ({
                               accountId,
                               accountRemark,
                               totalBalance,
                               purseId,
                               remark,
                             }: ITransferAccount & ITransferPurse) => {
    onChange({ accountId, accountRemark, totalBalance, purseId, remark });
    closeAllModal();
  };

  const closeAllModal = () => {
    setOpenModalPurse(false);
    setOpenModal(false);
  };

  const handleCloseModalAccount = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ContainerStep onClick={handleClickContainer}>
        {title && <Typography>{title}</Typography>}
        {label}
      </ContainerStep>

      {
        isOpenModal && <Suspense fallback={<Loading type={'fullScreen'} />}>
          <ModalFilterAccount isShow={isOpenModal} handleClose={handleCloseModalAccount}
                              handleApplyAccount={handleApplyAccount} />
        </Suspense>
      }

      {
        isOpenModalPurse && <Suspense fallback={<Loading type={'fullScreen'} />}>
          <ModalFilterPurses isShow={isOpenModalPurse} handleClose={closeAllModal} account={account}
                             handleApplyPurse={handleApplyFilter} />
        </Suspense>
      }
    </>
  );
}
