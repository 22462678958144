import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { HolderInfo } from '../HolderInfo';
import { useTranslation } from 'react-i18next';
import { HolderCard } from '../HolderCard';
import { HolderListButton } from '../HolderListButton';
import { useActiveHolder, useActiveRoutes } from '../../../../../../../hooks';
import { filterObject } from '../../../../../../../utils';
import { InputSearchLine, Loading } from '../../../../../../ui';
import { HolderInfoProps, HolderProps } from '../../../../../../../interface';
import { holdersListService, tokenService } from '../../../../../../../services';
import { useNavigate } from 'react-router-dom';


export const HolderList: FC<HolderInfoProps> = memo((props) => {
  const { t } = useTranslation();
  const { changeHolderId, activeRole, activeHolderId } = useActiveHolder();


  const { data: dataHolders = { holders: [] }, isLoading } = holdersListService.useGetHoldersQuery('');
  const [selectedHolder, setSelectedHolder] = useState(Number(props.id));
  const [searchStr, setSearchStr] = useState('');
  const [firstRender, setFirstRender] = useState(true);
  const [retakeToken] = tokenService.useLazyRetakeTokenQuery();
  const handleOnChangeSearchStr = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => { setSearchStr(value); };

  const allowRoutes = useActiveRoutes();

  const navigate = useNavigate();

  useEffect(() => {
    if (!firstRender) {
      const link = allowRoutes[0].link.replace(':holderId', activeHolderId.toString());
      navigate(link, { replace: true });
    }
  }, [activeHolderId, activeRole, firstRender]);


  const handleOnSelect = async () => {
    changeHolderId(selectedHolder);
    setFirstRender(false);
    await retakeToken({ holderId: selectedHolder })
      .then(() => { props.onClose && props.onClose(); });
  };

  const handleOnClick = (holderId: number) => { setSelectedHolder(holderId); };

  const rows = useMemo(() => {
    return filterObject({ arr: dataHolders.holders, fields: ['okpo', 'name'], searchStr });
  }, [dataHolders, searchStr]);

  const renderHolders = useMemo(() => {
    return rows.map((holder: HolderProps) => <HolderCard key={holder.id} holder={holder} selectedId={selectedHolder}
                                                         onClick={handleOnClick} />);
  }, [rows, props.id, handleOnClick]);


  return (
    <Box sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', width: '300px' }}>
      <Box sx={{ display: 'flex', width: '300px' }}>
        <HolderInfo {...props} />
      </Box>
      { /* Если фирм больше 4 отображаем поисковую строку */
        dataHolders.holders.length > 4 &&
        <InputSearchLine placeholder={t('module.common.title.titleSearchStr')} onChange={handleOnChangeSearchStr} />
      }

      <Box
        sx={{
          display: 'block',
          flexDirection: 'column',
          marginTop: '1rem',
          padding: '.4rem .2rem',
          height: '215px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>

        {
          isLoading
            ? <Loading type={'small'} />
            : renderHolders
        }

      </Box>
      <HolderListButton onSelect={handleOnSelect} onClose={() => props.onClose && props.onClose()} />
    </Box>
  );
});
