import { IBillingDoc } from '../interface.billing';
import { getPDV, getWorkBook, saveXLSFile } from '../../commonMethods';
import moment from 'moment';
import { ClientBills } from '../../client-bills/clientBills';
import { getNumberToString } from '../../../../../../utils';

export const getBvsXLSXFile = async ({
                                       numBill, holder, tax, sum,
                                     }: IBillingDoc) => {

  const { convertedInteger, integerCurrency, fractionalString, fractionalCurrency } = getNumberToString(sum);
  const sumToString = `${convertedInteger} ${integerCurrency} ${fractionalString} ${fractionalCurrency}`;
  /**
   * ПДВ и Сумма без ПДВ
   */
  const { pdv, bezPdv } = getPDV(sum, tax);
  /**
   * ПДВ
   */
  const PDV = pdv;

  const wb = await getWorkBook(ClientBills.BVS);
  const ws = wb.worksheets[0];
  ws.pageSetup.margins = {
    left: 0.1, right: 0, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3,
  };

  /**
   * Переопределяем ширину ячеек
   */
  ws.getColumn('B').width = 16;
  ws.getColumn('E').width = 10;
  ws.getColumn('F').width = 6.4;
  ws.getColumn('G').width = 10;
  ws.getColumn('H').width = 10;
  ws.getColumn('I').width = 10;
  ws.getColumn('J').width = 12;
  ws.getColumn('K').width = 9;

  /**
   * Одержувач телефон
   */
  ws.getCell(9, 3).value = holder.fullName;
  ws.getCell(10, 3).value = `тел. ${holder.phone}`;
  /**
   * Платник
   */
  ws.getCell(12, 3).value = holder.contractNumber;
  /**
   * Призначення платежу
   */
  ws.getRow(13).height = 30;
  ws.getCell(13, 3).value = `Покупці палива через Мпос, дог.№ ${holder.contractNumber} зг. рах.№ ${numBill} від ${moment().format('DD.MM.YY')}`;
  /**
   * Номер рахунку
   */
  ws.getCell(15, 3).value = `Рахунок № ${numBill}`;
  /**
   * Від якого числа
   */
  ws.getCell(16, 7).value = `від ${moment().format('DD.MM.YY')} р.`;
  /**
   * Найменування за що
   */
  ws.getCell(19, 2).value = 'Поповнення рахунку';
  /**
   * Кол-во
   */
  ws.getCell(19, 7).value = sum;
  /**
   * Ставка ПДВ
   */
  ws.getCell(19, 8).value = `${tax}%`;
  /**
   * Сума
   */
  ws.getCell(19, 9).value = sum;
  /**
   * Разом без ПДВ
   */
  ws.getCell(20, 9).value = bezPdv;
  /**
   * ПДВ
   */
  ws.getCell(21, 9).value = PDV;
  /**
   * Разом без ПДВ
   */
  ws.getCell(22, 9).value = sum;
  /**
   * Сума прописом
   */
  ws.getCell(25, 1).value = sumToString;

  /**
   * Устанавливаем область печати
   */
  ws.pageSetup.printArea = 'A1:J29';

  await saveXLSFile(wb, numBill);
};
