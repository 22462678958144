import { alpha, styled } from '@mui/material/styles';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DatePickerFilled = styled((props: any) => (
  <DatePicker
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    sx={{ ...props.sx }}
    {...props}
    label={props.label.toUpperCase()}
  />
))(({ theme }) => {
  return ({
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        border: '1px solid #000',
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiFilledInput-input': {
        fontFamily: theme.typography.fontFamily,
      },
    },
    '& .MuiInputBase-root:before, .MuiFilledInput-root:after': { display: 'none' },
  });
});
