import { RequestMoneyTransferProps } from '../../../interface';


export const initialMoneyTransfer: RequestMoneyTransferProps = {
	sourceAccountId: -1,
	destinationAccountId: -1,
	purseId: -1,
	value: 0
}

export interface ITransferAccount {
	accountId: number | undefined;
	accountRemark: string;
	totalBalance?: number;
}

export const initialAccount: ITransferAccount = {
	accountId: undefined,
	accountRemark: ''
}

export interface ITransferPurse {
	purseId: number | undefined;
	remark: string;
	totalBalance: number;
}

export const initialPurse: ITransferPurse = {
	purseId: undefined,
	remark: '',
	totalBalance: 0
}

