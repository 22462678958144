import { styled } from '@mui/material/styles';
import { CardMedia } from '@mui/material';
import { CardMediaProps } from '@mui/material/CardMedia/CardMedia';

export const Logo = styled((props: CardMediaProps) => (
  <CardMedia component='img' sx={{width: 'initial'}} image={'./img/logo.png'} {...props} />))(({ theme }) => ({
  maxWidth: '190px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    margin: '5px .5rem 0 auto',
  },
}));
