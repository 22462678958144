import { styled } from '@mui/material/styles';
import { Autocomplete as AutocompleteM, AutocompleteProps } from '@mui/material';

import { TextFieldFilled } from '../../../../../styles';
import React from 'react';

type CustomAutocompleteType = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & { label: string }

export const Autocomplete = styled(({ label, ...props }: CustomAutocompleteType) => (
  <AutocompleteM
    disableClearable={true}
    color={'primary'}
    {...props}
    renderInput={(params) =>
      <TextFieldFilled {...params}
                       sx={{
                         fontWeight: '700',
                         '& .MuiFilledInput-root:before, .MuiFilledInput-root:after':
                           { display: 'none' },
                       }}
                       variant='filled'
                       label={label.toUpperCase()}
      />}
  />
))(() => ({
  width: '100%',
  '& .MuiFilledInput-root': {
    fontWeight: '700',
  },
}));
