import { bubble as Menu } from 'react-burger-menu';
import React, { useMemo, useState } from 'react';
import { ChangeLanguage } from '../../../Credentials';
import { burgerMenu } from './style';
import { Logo } from '../../style';
import { useCheckPermission } from '../../../../../hooks';


interface IMenuMobile {
  menu: JSX.Element;
}

export default function MenuMobile({ menu }: IMenuMobile) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { checkPermission } = useCheckPermission();
  /* Получаем доступность */
  const isAllowChangeLanguage = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'changeLanguage' });
  }, [checkPermission]);

  return (<div>
    <Menu width={'240px'}
          styles={burgerMenu}
          isOpen={isOpen}
    >
      <Logo />
      {
        isAllowChangeLanguage && <ChangeLanguage sx={{ marginBottom: '1rem' }} />
      }

      <main style={{ width: '100%' }}>
        {menu}
      </main>
    </Menu>

  </div>);
}
