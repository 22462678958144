import * as React from 'react';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { initialMoneyTransfer, ITransferAccount, ITransferPurse } from './interface';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ContainerStepItem, SourceSum, TransferButton } from './components';
import { Grid, Typography } from '@mui/material';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useActiveHolder } from '../../../hooks';
import { Loading, Modal } from '../../ui';

import { useTranslation } from 'react-i18next';
import { RequestMoneyTransferProps } from '../../../interface';
import { transformResponseError, moneyTransferMutation } from '../../../services';
import { LoadingButton } from '@mui/lab';

const ContainerTransfer = styled(Box)(() => ({
  padding: '1rem',
  maxWidth: '500px',
}));

const BalanceLabel = styled(Typography)(() => ({
  position: 'absolute',
  top: '.5rem',
  right: '.5rem',
  display: 'flex',
  alignItems: 'center',
}));


export default function MoneyTransfers() {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();

  const [transfer, setTransfer] = useState<RequestMoneyTransferProps>(initialMoneyTransfer);

  useEffect(() => {
    initForm();
  }, [activeHolderId]);

  const initForm = () => {
    setTransfer(initialMoneyTransfer);
    setSourceRemark('' as any);
    setDestinationRemark('' as any);
  };

  const [setMoneyTransfer, { isLoading: isLoadingMoneyTransfer }] = moneyTransferMutation.useSetMoneyTransferMutation();

  const [sourceRemark, setSourceRemark] = useState<ITransferAccount & ITransferPurse>();
  const [destinationRemark, setDestinationRemark] = useState<ITransferAccount>();
  const [isOpenModal, setOpenModal] = useState(false);

  const handleChangeAccount = (type: 'sourceAccountId' | 'destinationAccountId', transferData: ITransferAccount & ITransferPurse) => {

    let moneyTransfer = {};

    if (type === 'sourceAccountId') {
      setSourceRemark({
        accountId: transferData.accountId,
        accountRemark: transferData.accountRemark,
        totalBalance: transferData.totalBalance,
        purseId: transferData.purseId,
        remark: transferData.remark,
      });
      moneyTransfer = {
        sourceAccountId: transferData && transferData?.accountId ? transferData.accountId : -1,
        purseId: transferData.purseId,
      };
    }

    if (type === 'destinationAccountId') {
      setDestinationRemark({
        accountId: transferData.accountId,
        accountRemark: transferData.accountRemark,
        totalBalance: transferData.totalBalance,
      });
      moneyTransfer = {
        destinationAccountId: transferData && transferData?.accountId ? transferData.accountId : -1,
      };
    }

    setTransfer(prev => ({ ...prev, ...moneyTransfer }));
  };

  const remarkSource = useMemo(() => {

    if (!sourceRemark) return (
      <ContainerStepItem>
        <CreditCardIcon />
        <Typography
          sx={{ paddingLeft: '.5rem' }}> {t('module.transfer.titleAccountSourceHelper')} </Typography>
      </ContainerStepItem>
    );

    return (
      <ContainerStepItem property={JSON.stringify({ justifyContent: 'flex-start' })}>
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid sx={{ display: 'flex' }}>
            <CreditCardIcon />
            <BalanceLabel>
              <PaymentsIcon sx={{ marginRight: '.2rem' }} />
              {sourceRemark.purseId === 0 ? sourceRemark.totalBalance.toFixed(2) : sourceRemark.totalBalance}
            </BalanceLabel>

            <Typography sx={{ paddingLeft: '.5rem' }}>
              {`ID: ${sourceRemark.accountId}, ${t('module.setting.users.titleAccountRemark')}: "${sourceRemark.accountRemark}"`}
            </Typography>
          </Grid>
          <Grid sx={{ display: 'flex' }}>
            <LabelImportantIcon />
            <Typography sx={{ paddingLeft: '.5rem' }}>
              {`${t('module.reports.titlePurse')}: ${sourceRemark.remark}`}
            </Typography>
          </Grid>
        </Grid>
      </ContainerStepItem>
    );
  }, [sourceRemark]);

  const remarkDestination = useMemo(() => {
    if (!destinationRemark) return (
      <ContainerStepItem>
        <CreditCardIcon />
        <Typography
          sx={{ paddingLeft: '.5rem' }}> {t('module.transfer.titleAccountDestinationHelper')} </Typography>
      </ContainerStepItem>
    );

    return (
      <ContainerStepItem property={JSON.stringify({ justifyContent: 'flex-start' })}>
        <CreditCardIcon />
        <BalanceLabel>
          {
            transfer.purseId === 0 && destinationRemark && destinationRemark.totalBalance &&
            <>
              <PaymentsIcon sx={{ marginRight: '.2rem' }} /> {destinationRemark.totalBalance.toFixed(2)}
            </>
          }

        </BalanceLabel>
        <Typography sx={{ paddingLeft: '.5rem' }}>
          {`ID: ${destinationRemark.accountId}, ${t('module.setting.users.titleAccountRemark')}: "${destinationRemark.accountRemark}"`}
        </Typography>
      </ContainerStepItem>
    );
  }, [destinationRemark, transfer.purseId]);

  const handleTransferMoney = async () => {
    await setMoneyTransfer({ transferData: transfer, holderId: activeHolderId })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
      });
    setOpenModal(false);
    initForm();
  };

  const handleCheckDisabledCommit = useMemo(() => {
    let check = false;

    for (const item of Object.values(transfer)) {
      if (item <= -1) {
        check = true;
        break;
      }
    }

    if (transfer.value === 0) check = true;
    if (sourceRemark && sourceRemark.totalBalance === 0) check = true;
    if (sourceRemark && sourceRemark.totalBalance < transfer?.value) check = true;

    return check;

  }, [transfer]);

  return (
    <>
      <ContainerTransfer>
        <Typography
          sx={{ marginTop: '1rem' }}>{t('module.transfer.titleAccountSource')}</Typography>

        <TransferButton
          type={'source'}
          label={remarkSource}
          onChange={(transfer) => handleChangeAccount('sourceAccountId', transfer)}
        />

        <SourceSum
          maxSum={sourceRemark?.totalBalance}
          onChange={(num) => setTransfer(prev => ({ ...prev, value: num }))}
          value={transfer.value || 0}
        />

        <Typography>{t('module.transfer.titleAccountDestination')}</Typography>
        <TransferButton
          type={'destination'}
          label={remarkDestination}
          onChange={(transfer) => handleChangeAccount('destinationAccountId', transfer)}
        />

        <LoadingButton
          loading={isLoadingMoneyTransfer}
          variant={'contained'}
          sx={{ width: '100%' }}
          disabled={handleCheckDisabledCommit}
          onClick={() => setOpenModal(true)}
        >{t('module.transfer.titleTransferButton')}
        </LoadingButton>
      </ContainerTransfer>
      {
        isOpenModal && <Suspense fallback={<Loading type={'fullScreen'} />}>
          <Modal
            handleBtnOk={handleTransferMoney}
            isOpen={isOpenModal}
            handleBtnCancel={() => setOpenModal(false)}
          >
            <>{t('module.transfer.confirmTransfer')}</>
          </Modal>
        </Suspense>
      }
    </>
  );
}
