import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HolderGroupTitle, InnerContainer } from '../../../common';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { ComboBox } from '../../../ui/input';
import { styled } from '@mui/material/styles';

import FilterTable from '../../../ui/filter/FilterTable';
import CustomTable from '../../../ui/custom-table';

import { IFilter, IOrderBy, ISingleRow } from '../../../ui/custom-table/interface/interface.custom';

import { getColumnsCard } from './cards.columns';
import { filterApply } from './filter/filter';
import { IFilterCardsField, initialStateFilterCard } from './filter/interface.filter';
import FilterContext from './filter/context.card';
//import DriverCards from '../../DriverCards/DriverCards';
import { IRow } from '../../../ui/custom-table/components/Rows/Rows';

import { getAccountIdFromParams, getFormatListAccount } from './utils/utils';
import { useActiveHolder, useAppDispatch, useCheckPermission } from '../../../../hooks';
import { accountsService, cardListService, cardMutation, getStorage, setStorage, transformResponseError } from '../../../../services';
import { COUNT_PER_ON_PAGE, Storage } from '../../../../config';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '../../../ui';
import { RequestCardDataUpdateProps } from '../../../../interface';

const AccountCombobox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginTop: '1rem',
  },
}));

/* Кол-во строк в таблице */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

export default function CardsList() {
  const { t } = useTranslation();

  /* Получаем адресную строку */
  const params = useParams();

  /* Управление состоянием */
  const dispatch = useAppDispatch();
  /* Управляем линками */
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  /* Получаем список счетов выбранного клиента */
  const { activeHolderId, activeHolder } = useActiveHolder();
  const {
    data: dataAccount = { accounts: [] },
    isLoading: isLoadingAccount,
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const pAccountId = useMemo(() => (getAccountIdFromParams(params)), [params?.accountId]);

  const { data: dataCards = { cards: [] }, isLoading } = cardListService.useGetCardListQuery({
    accountId: pAccountId,
    holderId: activeHolderId,
  });

  useEffect(() => {
    if (params) {
      const startPageCardList: number = parseInt(searchParams.get('page') || '1');
      setPage(startPageCardList - 1);

      const filter = searchParams.get('filter') || '';
      if (filter !== '') {
        setFilterState(JSON.parse(filter));
      } else {
        const storageFilter = getStorage(Storage.listCardFilter) as string;
        storageFilter && setFilterState(JSON.parse(storageFilter));
      }
    }
  }, []);

  const handleChangeAccount = (selectedAccount: any) => {
    searchParams.set('page', `1`);
    setSearchParams(searchParams);
    navigate(`/holder/${activeHolderId}/account/${selectedAccount}/cards?${searchParams}`);
    setPage(0);
  };

  /* Фильтр таблицы */
  const [filter, setFilterState] = useState<IFilterCardsField>(initialStateFilterCard);
  const [isFilterApply, setApplyFilter] = useState<boolean>(false);
  /* изменение фильтра */
  const handleChangeFilter = (newFilter: IFilterCardsField) => {
    searchParams.set('filter', JSON.stringify(newFilter));
    setSearchParams(searchParams);
    setStorage(Storage.listCardFilter, JSON.stringify(newFilter));
    setFilterState(newFilter);
  };

  const handleClearFilter = () => {
    searchParams.set('filter', '');
    setSearchParams(searchParams);
    setStorage(Storage.listCardFilter, JSON.stringify(initialStateFilterCard));
    setFilterState(initialStateFilterCard);
    handleCommitFilter();
  };
  /* Применение фильтра */
  const handleCommitFilter = useCallback(() => {
    setApplyFilter(prev => !prev);
  }, [filter]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    setPage(page - 1);
  };

  const cbChangePage = (page: number) => {
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
    setPage(page - 1);
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    /* Применение фильтра */
    let filteredArr = filterApply({ cards: dataCards.cards, filter, orderBy }) || [];
    const count = filteredArr.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    return { arr: filteredArr, count };

  }, [pageNum, isFilterApply, orderBy, dataCards, params]);

  const [updateCard] = cardMutation.useUpdateCardDataMutation();

  const handleChangeCardStatus = async ({ row }: ISingleRow): Promise<boolean> => {
    const newCardData: RequestCardDataUpdateProps = {
      status: row.status,
      mobile: row.mobile,
      vin: row.vin,
      cardInfo: row.cardInfo,
    };

    return updateCard({ request: newCardData, cardId: row.cardId, isUpdateCard: true })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error) });
        return !isError;
      });

  };

  /* Заполняем табличные заголовки и проверяем права доступа */
  const { checkPermission } = useCheckPermission();
  const { activeRole } = useActiveHolder();
  const columns = useMemo(() => {

    return getColumnsCard({ role: activeRole, onToggleSwitch: handleChangeCardStatus, checkPermission });
  }, [t, handleChangeCardStatus, checkPermission, activeRole]);

  const handleDoubleClick = (row: IRow) => {
    const startPageCardList = searchParams.get('page') || '1';
    const from = searchParams.get('from') || 'client';
    navigate(`/holder/${activeHolderId}/account/${row.accountId}/card/${row.cardId}?page=${startPageCardList}&filter=${encodeURIComponent(JSON.stringify(filter))}`);
  };

  const listAccount = useMemo(() => (getFormatListAccount(dataAccount.accounts)), [dataAccount.accounts]);
  {/*
   //const role = getStorage('role');
   const matches = useMediaQuery('(max-width:1024px)');

   if (role === 'SmartCardsDriver' && matches) {
   return  <></>

   <DriverCards />;

   }  */
  }

  return <>
    <FilterContext.Provider value={{ filter }}>
      <InnerContainer direction={'left'} show={true} sx={{ zIndex: 0 }}>
        <ContainerRow>

          <Grid item xs={12} md={9} style={{ flexDirection: 'column', padding: '1rem' }}>
            {/* Выпадающий список клиентов */}
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>

              <AccountCombobox item xs={12} md={4}>
                <ComboBox
                  textStyle={{ fontWeight: '700' }}
                  data={listAccount}
                  fieldLabelOption={'remark'}
                  label={t('module.dataGroup.accounts')}
                  fnOnChange={handleChangeAccount}
                  defaultValue={pAccountId}
                />
              </AccountCombobox>
            </Grid>

          </Grid>

          <Grid item xs={12} md={9}
                style={{
                  display: 'flex', justifyContent: 'flex-start', padding: '1rem', alignItems: 'center',
                }}>
            <HolderGroupTitle mt={1}
                              title={`${t('module.common.title.titleCards')}`} />
            {/* Фильтр таблицы */}

            <FilterTable
              type={'cards'}
              onFilterClear={handleClearFilter}
              onFilterChange={handleChangeFilter}
              onFilterCommit={handleCommitFilter}
            />

          </Grid>

          <Grid item xs={12} md={10} style={{ flexDirection: 'column', padding: '1rem' }}>

            <CustomTable
              rows={rows.arr}
              columns={columns}
              isLoading={isLoadingAccount || isLoading}
              count={rows.count}
              labelEmptyRows={t('module.common.table.emptyRows')}
              rowsPerPageOptions={[countPerOnPage]}
              fnRefreshData={handleChangePage}
              cbChangePage={cbChangePage}
              pageNum={pageNum}
              accountId={pAccountId}
              holderId={activeHolderId}
              onDoubleClick={handleDoubleClick}
              sx={{ '& .MuiTableCell-root:first-child': { padding: '4px 0 0' } }}
            />

          </Grid>


        </ContainerRow>
      </InnerContainer>
    </FilterContext.Provider>
  </>;
}
