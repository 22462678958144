import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../../ui/custom-table/interface/interface.custom';
import i18n from 'i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { GetAccessProps } from '../../../../interface';
import { Roles } from '../../../../helpers';

interface IGetColumnsCard {
  role: Roles;

  /**
   * Изменения статуса счета
   * @param row
   */
  onToggleSwitch({ row }: ISingleRow): Promise<boolean>;

  /**
   *  Проверяем доступность к методам
   */
  checkPermission<T>({ accessPoint }: Pick<GetAccessProps, 'accessPoint'>): T;
}

export const getColumnsCard = ({ role, onToggleSwitch, checkPermission }: IGetColumnsCard): IColumn[] => {


  const returnColumns: IColumn[] = [
    {
      field: 'hasLimits',
      label: '',
      formatHeader: 'empty',
      cell: { format: 'check-card-limit', tooltip: i18n.t('module.cards.tooltipNoLimit') },
      align: 'left',
      alignData: 'left',
      sortable: true,
      width: 5,
    },
    {
      field: 'cardId',
      label: i18n.t('module.cards.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
      width: 130,
    },

    {
      field: 'accountRemark',
      label: i18n.t('module.common.title.titleAccountComboBox'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
    },
    {
      field: 'fio',
      label: i18n.t('module.cards.titleName'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
    },

    {
      field: 'cardStatus',
      label: i18n.t('module.common.table.active'),
      formatHeader: 'default',
      cell: {
        format:
          checkPermission<boolean>({ accessPoint: 'cards.access.status.isAllow' })
            ? 'popup-status-card'
            : 'boolean',
        onToggleSwitch,
        allowAccessStatus: checkPermission<number[]>({ accessPoint: 'cards.access.status.allowStatus' }) || [],
        switchTooltip: [i18n.t('module.cards.qr.status.0'), i18n.t('module.cards.qr.status.1')],
      },
      align: 'center',
      alignData: 'right',
      sortable: true,
      width: 150,
    },
    {
      field: 'vin',
      label: i18n.t('module.cards.titleVin'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
    },
    {
      field: 'mobile',
      label: i18n.t('module.cards.titlePhone'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
      width: 100,
    },
  ];

  if (checkPermission<boolean>({ accessPoint: 'cards.access.setting.isAllow' })) {
    returnColumns.push({
      field: 'btnAccount',
      label: '',
      formatHeader: 'default',
      cell: { format: 'link-setting-card', label: i18n.t('module.common.table.setting'), icon: <SettingsIcon /> },
      align: 'center',
      alignData: 'center',
      width: 20,
    });
  }

  return [...returnColumns];
};
