import React, { memo, Suspense, useMemo, useState } from 'react';
import { getColumns } from './users.columns';
import { ContainerRow, CustomTable, InputSearchLine, Loading } from '../../ui';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { IRow } from '../../ui/custom-table/components/Rows/Rows';
import { HolderGroupTitle, InnerContainer } from '../../common';
import { useTranslation } from 'react-i18next';
import { userListService } from '../../../services/query/user.list.service';
import { COUNT_PER_ON_PAGE, ModalButton } from '../../../config';
import { IFilter, IOrderBy } from '../../ui/custom-table/interface/interface.custom';
import { useSearchParams } from 'react-router-dom';
import { ModalUserEditor, UserButtons } from './components';
import { usersMutation } from '../../../services/mutation/users.service';
import { useActiveHolder, useMediaQuery, useModal } from '../../../hooks';
import { filterObject } from '../../../utils';

const GridTitle = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  '&>.MuiGrid-root': {
    margin: 0,
  },
}));

/* Кол-во строк в таблице */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

export const Users = memo(() => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { showModal } = useModal();

  const { activeHolderId } = useActiveHolder();
  const [showUserCurrentHolder, setShowModeUser] = useState(false);
  const matches = useMediaQuery();


  const { data: dataUsers = { users: [] }, isLoading } = userListService.useGetUserListQuery({});
  const [sendDeleteRequest] = usersMutation.useDeleteUserMutation();
  const [sendDeleteOneHolderFromUserRequest] = usersMutation.useDeleteOneHolderFromUserMutation();
  const [personalAccountId, setPersonalAccountId] = useState<number>(-1);
  const [searchStr, setSearchStr] = useState('');

  /* Удаление пользователя */
  const handleDeleteUser = (personalAccountUserId: number) => {
    sendDeleteRequest({ personalAccountUserId });
  };

  const handleDeleteOneHolder = (personalAccountUserId: number) => {
    sendDeleteOneHolderFromUserRequest({ personalAccountUserId, holderId: activeHolderId });
  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ handleDeleteUser, handleDeleteOneHolder, showUserCurrentHolder });
  }, [t, handleDeleteUser, handleDeleteOneHolder, showUserCurrentHolder]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    setPage(page - 1);
  };

  const cbChangePage = (page: number) => {
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
    setPage(page - 1);
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    /* Применение фильтра */
    let filteredArr = dataUsers.users;

    filteredArr = filterObject({ arr: filteredArr, fields: ['fio', 'email', 'phoneNumber'], searchStr });

    /* Фильтруем по фирме */
    if (showUserCurrentHolder) {
      /* Компания должна быть равна активной компании пользователя, также у него должно быть заполнено apiRole в котором больше одного символа */
      filteredArr = filteredArr.filter(user => user.holders.findIndex(holder => holder.holderId === activeHolderId && holder.apiRole && holder.apiRole.length > 0) !== -1);
    }

    const count = filteredArr.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    return { arr: filteredArr, count };

  }, [pageNum, orderBy, dataUsers, searchStr, showUserCurrentHolder, activeHolderId]);

  /* Отмечаем выдкленную строку */
  const handleMarkRow = (row: IRow) => {
    setPersonalAccountId(Number(row.id));
  };

  const handleDoubleClick = (row: IRow) => {
    setPersonalAccountId(Number(row.id));
    setOpenModal(true);
  };

  const handleCreateNewUser = () => {
    setPersonalAccountId(-1);
    setOpenModal(true);
  };

  const handleEditUser = () => {
    if (personalAccountId === -1) {
      showModal({

        buttonType: ModalButton.Cancel,
        children: (
          <Box sx={{ width: '300px' }}>
            <Typography>{t('module.common.filter.rowNotSelected')}</Typography>
          </Box>
        ),
      });
    } else {
      setOpenModal(true);
    }

  };

  const handleChangeModeOfShowUsers = (e: any) => {
    setShowModeUser(e.target.checked);
  };

  const styleGrid = useMemo(() => {
    return matches
      ? { margin: 'initial', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }
      : { margin: 'initial', display: 'flex', alignItems: 'center' };
  }, [matches]);

  return <>
    <InnerContainer direction={'left'} show={true} sx={{ zIndex: 0 }}>
      <ContainerRow>
        <GridTitle item xs={12} md={8} sx={styleGrid}>
          <Grid item xs={12} md={3}>
            <HolderGroupTitle title={t('menu.users.title')} mt={matches ? 1 : 0} sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputSearchLine
              placeholder={t('module.common.title.titleSearchStr')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchStr(e.currentTarget.value);
              }}
              style={{ margin: matches ? '1rem 0 0 0' : '0 2rem', borderRadius: '5px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginLeft: '2.5rem' }}>
              <UserButtons
                addBtn={{ onClick: handleCreateNewUser }}
                editBtn={{ onClick: handleEditUser }}
                allUser={{ onClick: handleChangeModeOfShowUsers }}
              />
            </Box>
          </Grid>

        </GridTitle>
        <GridTitle item xs={10} sx={{ margin: 'initial' }}>
          <CustomTable
            rows={rows.arr}
            columns={columns}
            isLoading={isLoading}
            count={rows.count}
            labelEmptyRows={t('module.common.table.emptyRows')}
            rowsPerPageOptions={[countPerOnPage]}
            fnRefreshData={handleChangePage}
            cbChangePage={cbChangePage}
            pageNum={pageNum}
            selectedRow
            onDoubleClick={handleDoubleClick}
            onClick={handleMarkRow}
          />
        </GridTitle>
      </ContainerRow>
    </InnerContainer>

    {
      isOpenModal && (
        <Suspense fallback={<Loading type={'fullScreen'} />}>
          <ModalUserEditor isShow={isOpenModal} handleClose={() => setOpenModal(false)}
                           personalAccountId={personalAccountId} />
        </Suspense>
      )
    }

  </>;
});
