import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { RequestCardDataCreateProps, RequestCardDataPhoneProps, RequestCardDataUpdateProps, RequestCardPinUpdateProps, RequestCardScheduleProps } from '../../interface';


export const cardMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    updateCardData: builder.mutation<void, { request: RequestCardDataUpdateProps, cardId: string, isUpdateCard?: boolean }>({
      async queryFn({ request, cardId, isUpdateCard = true }, {}, extraOptions, fetchWithBQ) {
        try {
          const { mobile, ...requestWithoutMobile } = request;
          const req = { ...requestWithoutMobile };

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/update`,
            method: 'POST',
            body: { ...req },
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: (result, error, { isUpdateCard }, meta) => {
        if (isUpdateCard) {
          return ['CardInfo', 'Cards'];
        }
        return [];
      },
    }),
    updatePhone: builder.mutation<void, RequestCardDataPhoneProps>({
      async queryFn(request, {}, extraOptions, fetchWithBQ) {
        try {

          const cardId = request.cardId;
          delete request.cardId;

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/update/phone`,
            method: 'POST',
            params: { ...request, lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardInfo', 'Cards'],
    }),
    deletePhone: builder.mutation<void, {cardId: number}>({
      async queryFn({cardId}, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/delete/phone`,
            method: 'DELETE',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardInfo'],
    }),
    setNewPin: builder.mutation<void, RequestCardPinUpdateProps>({
      async queryFn(request, {}, extraOptions, fetchWithBQ) {
        try {

          const cardId = request.cardId;
          delete request.cardId;

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/update/pin`,
            method: 'POST',
            body: request,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
    updateSchedules: builder.mutation<void, { cardId: string, schedule: RequestCardScheduleProps }>({
      async queryFn({ schedule, cardId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/schedules`,
            method: 'POST',
            body: schedule,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardSchedule'],
    }),
    createLimit: builder.mutation<void, { cardId: string, request: RequestCardDataCreateProps }>({
      async queryFn({ cardId, request }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/limits/create`,
            method: 'POST',
            body: request,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardLimit', 'CardInfo', 'Cards'],
    }),
    updateLimit: builder.mutation<void, { cardId: string, limitId: number, limitAmount: number }>({
      async queryFn({ cardId, limitId, limitAmount }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/limits/${limitId}/update`,
            method: 'POST',
            body: { limitAmount },
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardLimit', 'CardInfo', 'Cards'],
    }),
    deleteLimit: builder.mutation<void, { cardId: string, limitId: number }>({
      async queryFn({ cardId, limitId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/limits/${limitId}/delete`,
            method: 'DELETE',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['CardLimit', 'CardInfo', 'Cards'],
    }),
  }),

});
