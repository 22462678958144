export const {
  REACT_APP_BASE_URL: BASE_URL = '',
  REACT_APP_IS_LOG_REQUEST,
  REACT_APP_KEY_FOR_TOKEN: KEY_FOR_TOKEN = '',
  REACT_APP_COUNT_LINE_ON_PAGE: COUNT_PER_ON_PAGE = 5,
  REACT_APP_REPORT_COUNT_LINE_ON_PAGE: REPORT_COUNT_PER_ON_PAGE = 5,
  REACT_APP_TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE: TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE = 60,
  REACT_APP_TIMEOUT_VISIBILITY_ENTER_OTP_AFTER_CHANGE_PHONE: TIMEOUT_VISIBILITY_ENTER_OTP_AFTER_CHANGE_PHONE = 99,
  REACT_APP_ENCRYPT_KEY: ENCRYPT_KEY,
} = process.env;
