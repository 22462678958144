import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { LocalGridItem } from './style';
import { InputFilled, OutlineBox, SwitchBlock } from '../../../../ui';
import { useCheckPermission, useMediaQuery } from '../../../../../hooks';
import { ISingleRow } from '../../../../ui/custom-table/interface/interface.custom';
import { CardsProps, RequestCardDataUpdateProps } from '../../../../../interface';
import { LoadingButton } from '@mui/lab';
import { cardMutation, transformResponseError, cardStatusService, cardInfoService } from '../../../../../services';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const CardInfo = memo(() => {
  /* Получаем языковую локаль */
  const { t } = useTranslation();
  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();

  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);
  const { checkPermission } = useCheckPermission();
  const matches = useMediaQuery();

  const [card, setCard] = useState<RequestCardDataUpdateProps>({
    mobile: '',
    status: 0,
    vin: '',
    cardInfo: {
      firstName: '',
      lastName: '',
      cabinetEnabled: false,
      email: '',
    },
  });

  /* Получаем карту */
  const {
    data: dataCards = { cards: [] },
  } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });

  /* Получаем все доступные статусы карт */
  const { data: cardStatuses = { statuses: [] } } = cardStatusService.useGetCardStatusesQuery({});

  useEffect(() => {
    if (dataCards.cards.length > 0) {
      const selectedCard = dataCards.cards[0] as CardsProps;
      /* Подготавливаем из выборки карты, необходимое нам состояние */
      const newCard: RequestCardDataUpdateProps = {
        mobile: selectedCard.cardInfo?.mobile,
        vin: selectedCard.vin,
        status: selectedCard.status,
        cardInfo: {
          firstName: selectedCard.cardInfo?.firstName,
          lastName: selectedCard.cardInfo?.lastName,
          cabinetEnabled: selectedCard.cardInfo?.cabinetEnabled,
          email: selectedCard.cardInfo?.email,
        },
      };
      setCard(newCard);
    }
  }, [dataCards]);

  const status = useMemo(() => {
    const statusStr = cardStatuses.statuses.find(status => status.id === card.status);
    return statusStr && statusStr.remark;
  }, [cardStatuses.statuses, card]);

  const [updateCardData, { isLoading }] = cardMutation.useUpdateCardDataMutation();

  const validationSchema = yup.object().shape({
    lastName: yup.string().required(t('error.REQUIRED_FIELD')),
    firstName: yup.string().required(t('error.REQUIRED_FIELD')),
    vin: yup.string().required(t('error.REQUIRED_FIELD')),
  });

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const validationError = useCallback((name: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const field: any = errors[name];
    return ({ error: !!field, helperText: field?.message as any });
  }, [errors]);

  useEffect(() => {
    if (card) {
      setValue('lastName', card.cardInfo.lastName);
      setValue('firstName', card.cardInfo.firstName);
      setValue('vin', card.vin);
    }
  }, [card]);

  const onToggleSwitch = async ({ row }: ISingleRow) => {
    const cardStatus = row.status === 0 ? 1 : 0;
    setCard(prev => ({ ...prev, status: cardStatus }));
    //return true;//await handleSwitchStatusCard({ typeUpdate: 'status', row, locale, card, cards, accountId, dispatch, holderId });
    return cardStatus === 0;
  };

  const onToggleSwitchEnabledCabinet = async ({ row }: ISingleRow) => {
    const newRow: any = { ...row, cardInfo: { ...row.cardInfo, cabinetEnabled: !row.cardInfo.cabinetEnabled } };
    setCard(newRow as RequestCardDataUpdateProps);
    return !row.cardInfo.cabinetEnabled; //await handleChangeCardInfo({ row: newRow, locale, card, cards, accountId, dispatch, holderId });

  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (['lastName', 'firstName', 'email'].indexOf(name) !== -1) {
      setCard(prev => ({ ...prev, cardInfo: { ...prev.cardInfo, [name]: value } }));
    } else {
      setCard(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmitCardData = async () => {
    await updateCardData({ cardId: pCardId, request: card, isUpdateCard: true })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
      });
  };

  /* Получаем доступность */
  const isReadOnly = useMemo(() => {
    return !checkPermission<boolean>({ accessPoint: 'cards.access.setting.dataCard' });
  }, [checkPermission]);

  return (
    <Grid item xs={12} sx={{ display: 'flex', flexWrap: matches ? 'wrap' : 'nowrap' }}>
      {/* Группа */}

      <Grid item xs={12} md={11}>
        <OutlineBox sx={{ padding: '.18rem' }}>
          <Box style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <LocalGridItem item xs={12} md={5} sx={{ margin: 0 }}>
              <InputFilled
                name={'lastName'}
                label={t('module.common.filter.titleLastName')}
                onChange={handleOnChange}
                value={card.cardInfo.lastName}
                inputProps={register('lastName')}
                {...validationError('lastName')}
                disabled={isReadOnly}
              />
            </LocalGridItem>

            <LocalGridItem item xs={12} md={5}>
              <InputFilled
                name={'firstName'}
                label={t('module.common.filter.titleFirstName')}
                onChange={handleOnChange}
                value={card.cardInfo.firstName}
                inputProps={register('firstName')}
                {...validationError('firstName')}
                disabled={isReadOnly}
              />
            </LocalGridItem>

            <LocalGridItem item xs={12} md={5}>
              <InputFilled
                name={'vin'}
                label={t('module.common.filter.titleVIN')}
                onChange={handleOnChange}
                value={card.vin}
                inputProps={register('vin')}
                {...validationError('vin')}
                disabled={isReadOnly}
              />
            </LocalGridItem>

            <LocalGridItem item xs={12} md={5}>
              <InputFilled
                name={'email'}
                label={t('module.setting.users.titleEmail')}
                onChange={handleOnChange}
                value={card.cardInfo.email}
                disabled={isReadOnly}
              />
            </LocalGridItem>

            <LocalGridItem item xs={12} md={5} sx={{ padding: '0 .7rem' }}>
              <SwitchBlock
                title={''}
                onChangeSwitch={onToggleSwitch}
                labelInfo={t('module.common.table.active')}
                row={card}
                isLoading={status === ''}
                value={!card.status}
                editable={!isReadOnly}
              />

              <SwitchBlock
                title={''}
                onChangeSwitch={onToggleSwitchEnabledCabinet}
                labelInfo={t('module.cards.titleActiveMobileApp')}
                row={card}
                value={card.cardInfo.cabinetEnabled || false}
                editable={!isReadOnly}
              />
            </LocalGridItem>
            {
              !isReadOnly &&
              <LocalGridItem item xs={12} md={5}
                             sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant={'contained'}
                  loading={isLoading}
                  onClick={handleSubmit(handleSubmitCardData)}
                  disabled={isReadOnly}
                >
                  {t('module.common.title.titleSubmit')}
                </LoadingButton>
              </LocalGridItem>
            }

          </Box>
        </OutlineBox>
      </Grid>

    </Grid>
  );
});
