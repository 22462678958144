import i18next from 'i18next';
import { IColumn } from '../../../../../../ui/custom-table/components/Columns/Columns';
import { IRow } from '../../../../../../ui/custom-table/components/Rows/Rows';
import { ConfigReducerProps } from '../../../../../../../interface';
import { ISingleRow } from '../../../../../../ui/custom-table/interface/interface.custom';

export interface SelectRolesProps {
  label: string;
  value: string;
}

interface HoldersListProps {
  roles: SelectRolesProps[];
  disabled?: boolean;
  setting: ConfigReducerProps;

  handleChangeRole(row: IRow): void;

  handleChangeHolderAccount(row: IRow): void;

  handleChangeHolderCards(row: IRow): void;
}

export const getColumns = ({
                             roles,
                             handleChangeRole,
                             handleChangeHolderAccount,
                             handleChangeHolderCards,
                             setting,
                             disabled = false,
                           }: HoldersListProps): IColumn[] => {

  const onChangeRole = (row: IRow, value: string) => {
    const newRow = { ...row, roleName: value };
    handleChangeRole(newRow);
  };

  const onToggleSwitchAccount = async ({ row }: ISingleRow): Promise<boolean> => {
    const newRow = { ...row, limitAccounts: !row.limitAccounts };
    handleChangeHolderAccount(newRow);
    return true;
  };

  const onToggleSwitchCards = async ({ row }: ISingleRow): Promise<boolean> => {
    const newRow = { ...row, limitCards: !row.limitCards };
    handleChangeHolderCards(newRow);
    return true;
  };

  const columns: IColumn[] = [];
  columns.push(
    {
      field: 'name',
      label: i18next.t('module.common.title.titleClientComboBox'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 170,
    },
    {
      field: 'roleName',
      label: i18next.t('module.setting.users.titleApiRole'),
      formatHeader: 'default',
      cell: { format: 'select', data: roles, fieldValue: 'roleName', onChange: onChangeRole, disabled },
      align: 'center',
      alignData: 'center',
      sortable: false,

    });

  if (setting.config.accessControl) {
    columns.push(
      {
        field: 'limitAccounts',
        label: i18next.t('module.setting.user.limitAccounts'),
        formatHeader: 'small',
        formatProps: { fontSize: '.7rem' },
        cell: {
          format: 'limit-account-by-role',
          fieldValue: 'roleName',
          onToggleSwitch: onToggleSwitchAccount,
          disabled,
        },
        align: 'center',
        alignData: 'center',
        sortable: false,
        width: 80,
      },
      {
        field: 'limitCards',
        label: i18next.t('module.setting.user.limitCards'),
        formatHeader: 'small',
        formatProps: { fontSize: '.7rem' },
        cell: {
          format: 'limit-cards-by-account',
          fieldValue: 'roleName',
          parentValue: 'limitAccounts',
          onToggleSwitch: onToggleSwitchCards,
          disabled,
        },
        align: 'center',
        alignData: 'center',
        sortable: false,
        width: 80,
      });
  }


  return [...columns];
};
