import { Roles } from '../../../helpers';
import { RolePermissionProps } from '../../../interface';


export const accessRole: RolePermissionProps = {
  [Roles.Empty]: {
    changeLanguage: true,
    profile: { isAllowMenuItem: false },
    accounts: { isAllowMenuItem: false },
    cards: { isAllowMenuItem: false },
    myCard: { isAllowMenuItem: false },
    documents: { isAllowMenuItem: false },
    reports: { isAllowMenuItem: false },
    exchange: { isAllowMenuItem: false },
    setting: { isAllowMenuItem: false },
    helpersLink: { isAllowMenuItem: false },
    users: { isAllowMenuItem: false },
  },
  [Roles.Admin]: {
    changeLanguage: true,
    profile: {
      isAllowMenuItem: true,
    },
    accounts: {
      isAllowMenuItem: true,
      access: {
        status: {
          isAllow: true,
        },
        cards: {
          isAllow: true,
        },
      },
    },
    cards: {
      isAllowMenuItem: true,
      access: {
        status: {
          isAllow: true,
          allowStatus: [0, 1],
        },
        setting: {
          isAllow: true,
          dataCard: true,
          changePin: true,
          mobile: true,
          schedule: true,
        },
        limit: {
          isAllow: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
    },
    myCard: {
      isAllowMenuItem: true,
    },
    documents: {
      isAllowMenuItem: true,
    },
    reports: {
      isAllowMenuItem: true,
    },
    exchange: {
      isAllowMenuItem: true,
    },
    setting: {
      isAllowMenuItem: true,
      apiKey: true,
    },
    helpersLink: {
      isAllowMenuItem: true,
    },
    users: {
      isAllowMenuItem: true,
    },
  },
  [Roles.Manager]: {
    changeLanguage: true,
    profile: {
      isAllowMenuItem: true,
    },
    accounts: {
      isAllowMenuItem: true,
      access: {
        status: {
          isAllow: true,
        },
        cards: {
          isAllow: true,
        },
      },
    },
    cards: {
      isAllowMenuItem: true,
      access: {
        status: {
          isAllow: true,
          allowStatus: [0, 1],
        },
        setting: {
          isAllow: true,
          dataCard: true,
          changePin: true,
          mobile: true,
          schedule: true,
        },
        limit: {
          isAllow: true,
          add: true,
          delete: true,
          edit: true,
        },
      },
    },
    myCard: {
      isAllowMenuItem: true,
    },
    documents: {
      isAllowMenuItem: true,
    },
    reports: {
      isAllowMenuItem: true,
    },
    exchange: {
      isAllowMenuItem: true,
    },
    setting: {
      isAllowMenuItem: true,
      apiKey: true,
    },
    helpersLink: {
      isAllowMenuItem: true,
    },
    users: {
      isAllowMenuItem: false,
    },
  },
  [Roles.Viewer]: {
    changeLanguage: true,
    profile: {
      isAllowMenuItem: true,
    },
    accounts: {
      isAllowMenuItem: true,
      access: {
        status: {
          isAllow: false,
        },
        cards: {
          isAllow: false,
        },
      },
    },
    cards: {
      isAllowMenuItem: true,
      access: {
        status: { isAllow: false },
        setting: {
          isAllow: true,
          dataCard: false,
          changePin: false,
          mobile: false,
          schedule: false,
        },
        limit: {
          isAllow: true,
          add: false,
          delete: false,
          edit: false,
        },
      },
    },
    myCard: {
      isAllowMenuItem: true,
    },
    documents: {
      isAllowMenuItem: true,
    },
    reports: {
      isAllowMenuItem: true,
    },
    exchange: {
      isAllowMenuItem: false,
    },
    setting: {
      isAllowMenuItem: true,
      apiKey: false,
    },
    helpersLink: {
      isAllowMenuItem: true,
    },
    users: {
      isAllowMenuItem: false,
    },
  },
  [Roles.Driver]: {
    changeLanguage: true,
    profile: {
      isAllowMenuItem: false,
    },
    accounts: {
      isAllowMenuItem: false,
    },
    cards: {
      isAllowMenuItem: false,
    },
    myCard: {
      isAllowMenuItem: true,
    },
    documents: {
      isAllowMenuItem: false,
    },
    reports: {
      isAllowMenuItem: false,
    },
    exchange: {
      isAllowMenuItem: false,
    },
    setting: {
      isAllowMenuItem: true,
      apiKey: false,
    },
    helpersLink: {
      isAllowMenuItem: true,
    },
    users: {
      isAllowMenuItem: false,
    },
  },
  [Roles.Reporter]: {
    changeLanguage: true,
    profile: {
      isAllowMenuItem: false,
    },
    accounts: {
      isAllowMenuItem: false,
    },
    cards: {
      isAllowMenuItem: false,
    },
    myCard: {
      isAllowMenuItem: true,
    },
    documents: {
      isAllowMenuItem: false,
    },
    reports: {
      isAllowMenuItem: true,
    },
    exchange: {
      isAllowMenuItem: false,
    },
    setting: {
      isAllowMenuItem: true,
      apiKey: false,
    },
    helpersLink: {
      isAllowMenuItem: true,
    },
    users: {
      isAllowMenuItem: false,
    },
  },
};
