import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardsProps, CardsPropService } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const cardListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardList: builder.query<CardsPropService, { holderId: number, accountId: number, withChecked?: boolean }>({
      async queryFn({ holderId, accountId, withChecked = false }, {}, extraOptions, fetchWithBQ) {
        try {
          /* Проверяем какие данные для запроса списка карт переданы */
          let result: any;

          /* Если данные переданы по аккаунту */
          if (accountId && accountId !== 0) {
            result = await fetchWithBQ({ url: `/accounts/${accountId}/cards`, params: { lang: getLangIso() } });
          } else {
            /* Если аккаунт не передан тогда берем по holderId */
            result = await fetchWithBQ({ url: `/holders/${holderId}/cards`, params: { lang: getLangIso() } });
          }


          const data = result && checkErrors<any>({ result, meta: result.meta });

          data.cards = data && data.cards && data.cards.map((card: CardsProps) => {

            const checked = (withChecked) ? { checked: false } : {};

            return {
              ...card,
              ...checked,
              fio: `${card.cardInfo?.lastName || ''} ${card.cardInfo?.firstName || ''}`,
              cardStatus: card.status === 0,
              firstName: card.cardInfo?.firstName || '',
              lastName: card.cardInfo?.lastName || '',
              mobile: card.cardInfo?.mobile || '',
              email: card.cardInfo?.email || '',
            };
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['Cards'],
    }),
  }),
});
