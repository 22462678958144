import { Typography } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { Loading } from '../../loading';
import { Autocomplete } from './style/Autocomplete';


interface IData {
  id: number;
  label: string;
}

interface IProps {
  data: any;
  fieldLabelOption: string;

  fnOnChange(key: any): void;

  label: string;
  defaultValue?: number | string;
  disabled?: boolean;
  textStyle: React.CSSProperties;
  callbackType?: 'fullData' | 'onlyId';

  [key: string]: any;
}

interface IOptions {
  id: number;
  label: string;
}

export default function ComboBox({
                                   data,
                                   fieldLabelOption,
                                   fnOnChange,
                                   label,
                                   disabled = false,
                                   defaultValue = 0,
                                   textStyle = {},
                                   callbackType = 'onlyId',
                                   ...other
                                 }: IProps) {

  const [val, setVal] = useState<any>();
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {

    const newOptions: IData[] = data.map((item: any) => ({
      id: item.id,
      label: item[fieldLabelOption],
    }));
    const defaultOption: IData = newOptions.filter(o => o.id === ((defaultValue === 0 || defaultValue === -1) ? newOptions[0].id : defaultValue))[0];

    setVal(defaultOption);
    setOptions(newOptions);

  }, [defaultValue, data]);

  const haveInDataMinus = useMemo(() => {
    return !!data.find((item: any) => item.id === -1);
  }, [data]);

  if (!val) {
    return <Typography textAlign={'center'}><Loading type={'medium'} /></Typography>;
  }

  return (
    <Autocomplete
      disabled={disabled}
      defaultValue={defaultValue}
      value={val}
      label={label}
      options={options}
      isOptionEqualToValue={(option, value) => {
        return (option.id === value.id && option.label === value.label);
      }}
      onChange={(event: any, value: any): void => {
        if (value.id >= 0 || haveInDataMinus) {
          setVal(value);
          callbackType === 'onlyId' && fnOnChange(value.id);
          callbackType === 'fullData' && fnOnChange(value);
        }
      }}
      {...other}
    />
  );
}
