import { TFormatColumn } from '../../interface/interface.custom';
import { useMemo } from 'react';
import { Typography } from '@mui/material';


interface IFormatColumn {
  column: {
    formatHeader?: TFormatColumn;
    label: string;
  };
}

export default function FormatColumn({ column }: IFormatColumn) {

  return useMemo(() => {
    const { formatHeader, label } = column;

    let response: JSX.Element;

    switch (formatHeader) {
      case 'empty': {
        response = <></>;
        break;
      }

      case 'small' : {
        const { formatProps }: any = column;
        response = formatProps
          ? <Typography sx={{ fontSize: formatProps.fontSize }}>{label}</Typography>
          : <>Not fontSize</>
        break;
      }

      default: {
        response = <>{label}</>;
        break;
      }


    }

    return response;

  }, [column]);
}
