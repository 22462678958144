import { HolderDetailInfoProps } from './HolderDetailInfo';
import React, { memo, useMemo } from 'react';
import { attributesByHolderIdService } from '../../../../services';
import { HolderGroupTitle, InfoBlock } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useCustomStyles } from '../../../../styles';
import { GridItem, Loading } from '../../../ui';

interface HolderAttributesProps extends HolderDetailInfoProps {
  isAnalytic?: boolean;
}

export const HolderAttributes = memo(({ holder, isAnalytic = false }: HolderAttributesProps) => {
  const { t } = useTranslation();
  const { data = { attributes: [] } } = attributesByHolderIdService.useGetAttributesByHolderIdQuery({ holderId: holder?.holderId });
  const classes = useCustomStyles();

  const renderTitle = useMemo(() => {
    return isAnalytic
      ? t('module.dataGroup.analyticInfo')
      : t('module.dataGroup.additionalInfo');
  }, [t]);

  const renderAttributes = useMemo(()=>{
    return data.attributes.filter( attribute => ( attribute.isDynamic === isAnalytic ) );
  },[data.attributes, isAnalytic]);

  if (!holder) {
    return <Loading type={'medium'} />;
  }

  return (
    <>
      {/*  Заголовок группы */}
      <HolderGroupTitle title={renderTitle} />

      {/* Группа */ }
      <Grid item xs={ 12 } className={ classes.textDefault }
            style={ { display: "flex", alignItems: "center", flexWrap: "wrap", marginTop: "1rem", } }>


      {
        renderAttributes.length === 0
          ? <GridItem item
                      xs={ 12 }>{ t('module.common.title.titleDataNotFound' ) }</GridItem>
          : renderAttributes.map( attribute => (
            <GridItem item xs={ 12 } md={ 4 } key={ attribute.attributeRemark }>
              <InfoBlock title={ attribute.attributeRemark }
                         value={ attribute.value } sx={ { padding: '1rem 1.5rem 1rem 0' } }/>
            </GridItem>
          ) )
      }
      </Grid>
    </>
  );
});
