import React, { memo, useEffect, useMemo, useState } from 'react';
import { ContainerRow, InputPhone } from '../../../../ui';
import { LocalGridItem } from '../CardInfo/style';
import { cardInfoService, cardMutation } from '../../../../../services';
import { IconButton } from '@mui/material';
import { useCustomStyles } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RenewPhone from './components/RenewPhone';
import { useCheckPermission, useModal } from '../../../../../hooks';
import { ModalButton } from '../../../../../config';

export const CardPhone = memo(() => {
  const { t } = useTranslation();
  const classes = useCustomStyles();

  const [phone, setNewPhone] = useState<string>('');
  const [isOpenModal, setOpenModal] = useState(false);

  const { showModal } = useModal();

  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();

  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  /* Получаем карту */
  const {
    data: dataCards = { cards: [] },
  } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });

  /* Удаляем телефон */
  const [deletePhone] = cardMutation.useDeletePhoneMutation();

  useEffect(() => {
    dataCards.cards.length > 0 && setNewPhone(dataCards.cards[0].cardInfo?.mobile);
  }, [dataCards]);

  const handleEnterPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPhone(e.target.value);
  };

  const { checkPermission } = useCheckPermission();
  /* Получаем доступность */
  const isAllowChangePhone = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'cards.access.setting.mobile' });
  }, [checkPermission]);

  /* Удаляем телефон с подтверждением */
  const deletePhoneWithConfirm = () => {
    showModal({
      buttonType: ModalButton.OkCancel,
      children: t('module.common.title.titleDeletePhone'),
      callbackOk() {
        deletePhone({cardId:Number(pCardId)})
      }
    });
  };

  return (<>
      <LocalGridItem item xs={12} sx={{ paddingLeft: 0 }}>
        <ContainerRow className={classes.titleDefault} sx={{ padding: 0 }}>
          <InputPhone
            disabled={true}
            sx={{ '& .Mui-disabled': { backgroundColor: 'transparent !important' } }}
            inputType={'filled'}
            value={phone}
            onChange={handleEnterPhone}
            label={t('module.common.filter.titlePhone')}
            InputProps={{
              endAdornment: isAllowChangePhone && <>
                <IconButton onClick={() => setOpenModal(true)}> <EditIcon /></IconButton>|
                <IconButton onClick={deletePhoneWithConfirm}> <DeleteForeverIcon /></IconButton>
              </>,
            }}
          />
        </ContainerRow>

      </LocalGridItem>
      {
        isOpenModal && <RenewPhone isOpen={isOpenModal} onClose={() => setOpenModal(false)} />
      }
    </>
  );
});
