import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { HolderListButtonProps } from '../../../../../../../interface';
import { useAppDispatch, useModal } from '../../../../../../../hooks';
import { logOut } from '../../../../../../../store';
import { Button as ButtonCmp } from '../../../../../../ui';
import { ModalButton } from '../../../../../../../config';

const Button = styled(ButtonCmp)(() => ({
  padding: '.5rem 1rem',
  flex: 1,
  marginRight: '.5rem',
  justifyContent: 'center',
}));

export const HolderListButton: FC<HolderListButtonProps> = memo(({ onSelect, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { showModal } = useModal();

  const handleOnSelect = () => {
    onSelect();
  };

  const submitExit = () => {
    onClose();
    dispatch(logOut());
  };

  const handleOnExit = () => {
    showModal({
      buttonType: ModalButton.OkCancel,
      children: `${t('modal.confirmExit')}`,
      callbackCancel: onClose,
      callbackOk: submitExit,
    });
  };

  return (
    <Box justifyContent={'space-between'} display={'flex'} style={{ marginTop: '1rem' }}>
      <Button variant={'contained'} onClick={handleOnSelect}>{t('module.holders.btnSelectHolder')}</Button>
      <Button variant={'contained'} onClick={handleOnExit}>{t('module.login.btnLogout')}</Button>
    </Box>
  );
});
