import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import i18n from 'i18next';

export const getColumns = (  ): IColumn[] => {

	return [
		{
			field: 'collapse',
			label: '',
			formatHeader: 'empty',
			cell: { format: 'collapse', type: 'report-trn' },
			align: 'right'
		},
		{
			field: 'terminalDate',
			label: i18n.t('module.reports.titleDate' ),
			formatHeader: 'default',
			cell: { format: 'date-time' },
			align: 'center',
			alignData: 'center'

		},
		{
			field: 'cardId',
			label: i18n.t('module.reports.titleCard' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'center',
			alignData: 'center'
		},
		{
			field: 'terminalName',
			label: i18n.t('module.reports.titleNameAZS' ),
			formatHeader: 'default',
			cell: { format: 'default'},
			align: 'center',
			alignData: 'left',

		},
		{
			field: 'purseRemark',
			label: i18n.t('module.reports.titlePurse' ),
			formatHeader: 'default',
			cell: { format: 'default' },
			align: 'right',
			alignData: 'right'
		},
		{
			field: 'quantity',
			label: i18n.t('module.reports.titleQty' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'right',
			alignData: 'right'
		},
		{
			field: 'amount',
			label: i18n.t('module.reports.titleSum' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'right',
			alignData: 'right'
		},
		{
			field: 'discount',
			label: i18n.t('module.reports.titleDiscount' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'right',
			alignData: 'right'
		},
		{
			field: 'sumWithDiscount',
			label: i18n.t('module.reports.titleSumWithDiscount' ),
			formatHeader: 'default',
			cell: { format: 'numericalDigit' },
			align: 'right',
			alignData: 'right'
		}
	];
};
