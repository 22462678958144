import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputFilled, InputPhone, Loading, ProgressTimer, SwitchBlock } from '../../../../../ui';
import { LoadingButton } from '@mui/lab';
import { ISingleRow } from '../../../../../ui/custom-table/interface/interface.custom';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomStyles } from '../../../../../../styles';
import { TIMEOUT_VISIBILITY_ENTER_OTP_AFTER_CHANGE_PHONE } from '../../../../../../config';
import { CardsProps, RequestCardDataUpdateProps } from '../../../../../../interface';
import { toast } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';
import { cardInfoService } from '../../../../../../services/query/card.info.service';
import { cardMutation, cardSendService, registerOtpService, transformResponseError } from '../../../../../../services';


const Modal = lazy(() => import('../../../../../ui/modal/Modal'));

const timeoutVisibility: number = +TIMEOUT_VISIBILITY_ENTER_OTP_AFTER_CHANGE_PHONE;

const IconButtonCustom = styled(IconButton)(() => ({
  padding: 0,
}));

interface IRenewPhone {
  isOpen: boolean;

  onClose(): void;

  editable?: boolean;
}

const initialCard: RequestCardDataUpdateProps = {
  mobile: '',
  status: 0,
  vin: '',
  cardInfo: {
    firstName: '',
    lastName: '',
    email: '',
    cabinetEnabled: false,
  },
};

export default function RenewPhone({ isOpen, onClose }: IRenewPhone) {
  const { t } = useTranslation();

  const [card, setCard] = useState<RequestCardDataUpdateProps>(initialCard);
  const [otp, setOtp] = useState<string>('');
  const [isSendOTP, setSendOTP] = useState<boolean>(false);
  const [firstSend, setFirstSend] = useState(true);

  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();
  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);
  /* Получаем карту */
  const { data: dataCards = { cards: [] } } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });

  useEffect(() => {
    if (dataCards.cards.length > 0) {
      const selectedCard = dataCards.cards[0] as CardsProps;
      /* Подготавливаем из выборки карты, необходимое нам состояние */
      const newCard: RequestCardDataUpdateProps = {
        mobile: selectedCard.cardInfo.mobile,
        vin: selectedCard.vin,
        status: selectedCard.status,
        cardInfo: {
          firstName: selectedCard.cardInfo.firstName,
          lastName: selectedCard.cardInfo.lastName,
          cabinetEnabled: selectedCard.cardInfo.cabinetEnabled,
          email: selectedCard.cardInfo.email,
        },
      };
      setCard(newCard);
    }
  }, [dataCards]);

  const [updatePhone, {isLoading: isLoadingChangePhone}] = cardMutation.useUpdatePhoneMutation();
  const [sendOTP, { isLoading: isLoadingSendOTP }] = registerOtpService.useLazySendOTPQuery();
  const [updateCardData, { isLoading: isLoadingUpdateCard }] = cardMutation.useUpdateCardDataMutation();
  const [sendLink, { isLoading: isLoadingSendLink }] = cardSendService.useLazySendLinkQuery();

  const handleEnterPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCard(prev => ({ ...prev, mobile: e.target.value }));
  };


  const handleChangePhone = async () => {
    if (card && pCardId) {
      updatePhone({ cardId: pCardId, phone: card.mobile, otp  })
        .then(({ error }: any) => {
          const isError = !!error;
          transformResponseError({ isError, error: String(error), successToast: true });
          if (!isError) {
            timeoutSendingOTP();
            handleCloseModal();
          }
        });
    } else {
      toast({ type: 'error', message: t('error.PARSING_ERROR') });
    }
  };

  /* Закрываем модальное окно ввода нового номера телефона */
  const handleCloseModal = () => {
    timeoutSendingOTP();
    onClose();
  };

  const handleChangeOTP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  /* Отправка ОТП пароля */
  const handleSendOTP = () => {
    if (card && card.mobile.length < 12) {
      toast({ type: 'error', message: t('error.1004') });
    } else {
      setFirstSend(false);
      card.mobile && sendOTP({ phone: card.mobile })
        .then(({ error }: any) => {
          const isError = !!error;
          transformResponseError({ isError, error: String(error) });
          !isError && setSendOTP(true);
        });
    }
  };

  /* Отправка линка регистрации */
  const handleSendLink = () => {
    if (card && card.mobile.length < 12) {
      toast({ type: 'error', message: t('error.1004') });
    } else {
      card.mobile && sendLink({ phone: card.mobile, cardId: pCardId })
        .then(({ error }: any) => {
          const isError = !!error;
          transformResponseError({ isError, error: String(error), successToast: true });
        });
    }
  };

  /* Сохранение телефона без подтверждения */
  const handleSavePhoneWithoutConfirm = () =>{
    if (card && card.mobile.length < 12) {
      toast({ type: 'error', message: t('error.1004') });
    } else {
      updatePhone({ cardId: pCardId, phone: card.mobile, otp, checkOtp: false  })
        .then(({ error }: any) => {
          const isError = !!error;
          transformResponseError({ isError, error: String(error), successToast: true });
          if (!isError) {
            timeoutSendingOTP();
            handleCloseModal();
          }
        })
    }
  };

  const timeoutSendingOTP = () => {
    setSendOTP(false);
  };

  const onToggleSwitchEnabledCabinet = async ({ row }: ISingleRow): Promise<boolean> => {
    const newCard: any = {
      ...row,
      cardInfo: {
        ...row.cardInfo,
        cabinetEnabled: !row.cardInfo.cabinetEnabled,
      },
    };

    setCard(newCard as RequestCardDataUpdateProps);

    return await updateCardData({ cardId: pCardId, request: newCard as any, isUpdateCard: false })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        isError && setSendOTP(true);
        return !isError;
      });
  };


  return <>
    {
      isOpen &&
      <Suspense fallback={<Loading type={'fullScreen'} />}>
        <Modal
          handleBtnOk={handleSendOTP}
          isOpen={isOpen}
          type={'withoutBtn'}
        >
          <Box sx={{ width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

            <InputPhone inputType={'filled'} label={t('module.common.filter.titlePhone')} value={card?.mobile || ''}
                        onChange={handleEnterPhone} sx={{ marginBottom: '.3rem' }} />
            {
              !isSendOTP
                ? <>
                  <LoadingButton variant={'contained'} onClick={handleSendOTP} loading={isLoadingSendOTP}>
                    {
                      firstSend
                        ? t('module.cards.titleBtnSendOtp')
                        : t('module.cards.titleBtnRetrySendOtp')
                    }
                  </LoadingButton>
                  <Typography style={{ marginTop: '.5rem', textAlign: 'center' }}>
                    {t('module.common.title.titleOr')}
                  </Typography>
                  <LoadingButton loading={isLoadingChangePhone} style={{ marginTop: '.5rem' }} variant={'contained'}
                                 onClick={handleSavePhoneWithoutConfirm}>
                    {t('module.cards.titleBtnWithoutConfirm')}
                  </LoadingButton>
                </>
                : <>
                  <LoadingButton
                    loading={isLoadingSendOTP}
                    variant={'contained'}
                    onClick={handleSendOTP}
                    sx={{ position: 'relative' }}
                    disabled={true}
                  >
                    <Typography sx={{ marginRight: '.5rem', fontSize: '.9rem' }}>
                      {t('module.cards.titleBtnRetrySendOtp')}
                    </Typography>
                    <ProgressTimer timer={timeoutVisibility} type={'small'} onStopTimer={timeoutSendingOTP} />
                  </LoadingButton>

                  <InputFilled label={t('module.cards.titleCodeFromSMS')}
                               onChange={handleChangeOTP}
                               placeholder={'0000'}
                               value={otp}
                               type={'number'} sx={{ margin: '2rem 0 1rem 0 !important' }} />
                  {
                    // TODO:
                    // editable={checkPermission({ permissionName: 'Cards' }) > 1}
                  }
                  <SwitchBlock
                    title={t('module.cards.titleActiveMobileApp')}
                    onChangeSwitch={onToggleSwitchEnabledCabinet}
                    labelInfo={card && card.cardInfo.cabinetEnabled ? t('module.common.filter.titleIsActive') : t('module.common.filter.titleInactive')}
                    row={card && card}
                    sx={{ '& div > .MuiGrid-root:last-child': { padding: '0', height: '50px' } }}
                    value={card && card.cardInfo.cabinetEnabled}
                    editable={true}
                  />

                  <LoadingButton
                    loading={isLoadingUpdateCard}
                    variant={'contained'}
                    onClick={handleChangePhone}
                  >
                    {t('module.cards.titleBtnCommit')}
                  </LoadingButton>
                </>
            }
            <Button
              variant={'contained'}
              onClick={handleCloseModal}
              sx={{ marginTop: '2rem' }}
            >
              {t('module.common.title.titleCancel')}
            </Button>
          </Box>
        </Modal>
      </Suspense>
    }
  </>;
}
