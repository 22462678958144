import { memo, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { LocalGridItem } from '../CardInfo/style';
import { Button, ContainerColumn, ProgressTimer } from '../../../../ui';
import { Typography } from '@mui/material';
import { ModalButton, TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE } from '../../../../../config';
import { useMediaQuery, useModal } from '../../../../../hooks';
import { cardMutation, transformResponseError } from '../../../../../services';
import { useParams } from 'react-router-dom';

const timeoutVisibility = +TIMEOUT_VISIBILITY_PIN_AFTER_CHANGE;

export const CardPin = memo(() => {
  /* Получаем языковую локаль */
  const { t } = useTranslation();
  const matches = useMediaQuery();
  const { showModal } = useModal();

  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();
  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  const [setPin] = cardMutation.useSetNewPinMutation();

  const [isShowPin, setShowPin] = useState<boolean>(false);
  const [newPin, setNewPin] = useState<string>('0000');

  const genNewPinCode = (): string => {
    const min: number = 0;
    const max: number = 9999;

    const newPinMath = Math.floor(Math.random() * (max - min)) + min;
    let newPinStr = `${newPinMath}`;


    while (newPinStr.length <= 3) {
      newPinStr = '0' + newPinStr;
    }

    return newPinStr;
  };

  const handleConfirmChangePin = () => {
    showModal({
      buttonType: ModalButton.OkCancel,
      callbackOk: handleBtnChangePin,
      children: <>{t('module.cards.titleConfirmRenewPin')}</>,
    });
  };

  const handleBtnChangePin = () => {
    const newPin = genNewPinCode();

    setPin({ cardId: pCardId, pin: newPin })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        if (!isError) {
          setNewPin(newPin);
          /* Отображаем новый пин */
          setShowPin(prev => !prev);
        }
      });
  };

  return (
    <ContainerColumn>
      {
        isShowPin
          ? (
            <LocalGridItem item xs={12} sx={{ padding: matches ? 0 : '0 .9rem 0 0', textAlign: 'end' }}>
              <Button variant={'contained'} disabled={true}
                      sx={{ position: 'relative', width: '200px', padding: '.5rem' }}>
                <Typography sx={{ marginRight: '.3rem', fontSize: '.9rem' }}>
                  {`${t('module.cards.titleShowNewPin')}: `}</Typography>
                <Typography sx={{ marginRight: '1rem', fontSize: '.9rem' }} >{newPin}</Typography>
                <ProgressTimer timer={timeoutVisibility} type={'small'} onStopTimer={() => setShowPin(false)} />
              </Button>
            </LocalGridItem>
          )
          : (
            <LocalGridItem item xs={12} sx={{ padding: matches ? 0 : '0 .65rem 0 0', textAlign: 'end' }}>
              <LoadingButton variant={'contained'} fullWidth={matches}
                             onClick={handleConfirmChangePin}>{t('module.cards.titleShowNewPin')}</LoadingButton>
            </LocalGridItem>
          )
      }
    </ContainerColumn>
  );
});
