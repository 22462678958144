import { ContainerStep, ContainerStepItem } from './style';
import { Box, Typography } from '@mui/material';
import { InputNumber } from '../../../ui';
import * as React from 'react';
import { useMemo } from 'react';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useTranslation } from 'react-i18next';

interface ISourceSum {
  maxSum: number | undefined;
  value: number;

  onChange(sum: number): void;
}

export default function SourceSum({ value, maxSum = 0, onChange }: ISourceSum) {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(+e.target.value);
  };

  const showWarning = useMemo(() => {
    return value < 0 || value > maxSum;
  }, [value]);

  return (
    <ContainerStep>
      <ContainerStepItem property={JSON.stringify({ justifyContent: 'space-between' })}>
        <Typography
          sx={{ paddingLeft: '.5rem' }}>{t('module.common.title.titleSum')}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

          {showWarning && <DoNotDisturbAltIcon sx={{ fontSize: '2rem', color: '#FF0000' }} />}

          <InputNumber sx={{ width: '100%', textAlign: 'right' }}
                       placeholder={''}
                       type={'number'}
                       value={value}
                       onChange={handleChange}

          />

        </Box>
      </ContainerStepItem>
    </ContainerStep>
  );
}
