import { Box, Grid, MenuItem, Tab } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ContainerColumn, InputCheckbox, InputFilled, Modal, OutlineBox, SelectMini } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import { getStorage, holdersListService, transformResponseError, userProfileService } from '../../../../../services';
import { AccessAccountsProps, initialUser, UserHoldersProps, UserProps } from './interface';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { HolderAccount, HolderList } from '../../../Setting/components/Users/components/ModalUserEditor/components';
import { Roles } from '../../../../../helpers';
import InputPhone from '../../../../ui/input/InputPhone';
import { usersMutation } from '../../../../../services/mutation/users.service';
import { toast } from '../../../../../utils';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { produce } from 'immer';
import { useAppSelector, useModal } from '../../../../../hooks';
import { ModalButton, Storage } from '../../../../../config';
import { userListService } from '../../../../../services/query/user.list.service';

interface ModalUserEditorProps {
  personalAccountId: number;
  isShow: boolean;

  handleClose(): void;
}

const phoneRegExp = /^38[0-9]{3}[0-9]{3}[0-9]{4}$/;
//const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ModalUserEditor = memo(({ isShow, handleClose, personalAccountId }: ModalUserEditorProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState('1');

  const [getUserProfile, {
    data: dataUsers = {
      user: {
        personalAccountUserId: null,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        holders: [],
      },
    },
  }] = userProfileService.useLazyGetUserProfileQuery();

  const { data: dataHolders = { user: { holders: [] } } } = userProfileService.useGetUserProfileQuery({ isEdit: true });
  const { data: dataUsersCache = { users: [] } } = userListService.useGetUserListQuery({});

  useEffect(() => {
    getUserProfile({ userId: personalAccountId, isEdit: true })
      .then(({ isError, data }) => {
        if (!isError) {
          if (personalAccountId !== -1) {
            if (data && data.user) {
              setUser(data.user as UserProps);
              setValue('firstName', data.user.firstName);
              setValue('lastName', data.user.lastName);
            }
          } else {
            setUser(initialUser);
          }
        }
      });
  }, [personalAccountId]);

  const [user, setUser] = useState<UserProps>(initialUser);
  const [changedCriticalValue, setChangeCriticalValue] = useState(false);

  const validationSchema = yup.object().shape({
    lastName: yup.string().required(t('error.REQUIRED_FIELD')),
    firstName: yup.string().required(t('error.REQUIRED_FIELD')),
    phoneNumber: yup.string().required(t('error.REQUIRED_FIELD'))
      .matches(phoneRegExp, t('validation.ERROR_PHONE')),

  });

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  /**
   * Запрещено редактирование
   */
  const isDisabledEdit = useMemo(() => { return user.isReadOnlyProfile; }, [user]);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'phoneNumber') {
      setValue('phoneNumber', e.target.value);
    }
    if (['userCanEdit'].indexOf(e.target.name) !== -1) {
      setUser(prev => ({ ...prev, [e.target.name]: e.target.checked }));
    } else {
      setUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };


  const handleChangeAccount = (holderId: number, accessAccounts: AccessAccountsProps[]) => {
    setUser((prevUser) => {
      return produce(prevUser, (draftUser) => {
        if (!draftUser.holders) {
          draftUser.holders = [];
        }

        const idx = draftUser.holders.findIndex(holder => holder.holderId === holderId) || 0;

        // Создаем копию accessAccounts и присваиваем ее draftUser.holders[idx].accessAccounts
        draftUser.holders[idx].accessAccounts = [...accessAccounts];
      });
    });

  };

  const renderTabs = useMemo(() => {
    return (
      user.holders && user.holders.map((holder, idx) => {
        if (holder.apiRole !== Roles.Admin && !!holder.accessAccounts && holder.apiRole !== Roles.Driver && holder.limitAccounts) {

          return (
            <Tab key={holder.holderId} label={holder.name} value={(idx + 2).toString()} />
          );
        }
      })
    );
  }, [user]);

  const renderTabPanel = useMemo(() => {
    return (
      user.holders && user.holders.map((holder, idx) => {
        /* Проверяем какая выбранна роль и если это Admin тогда не отображаем таб */
        if (holder.apiRole !== Roles.Admin && !!holder.accessAccounts) {
          /* Проверяем есть ли карты в массивах счетов */
          const limitCardsByArray = !!holder.accessAccounts.find((account: any) => {
            return account.accessCardsLength > 0;
          });
          return (
            <TabPanel
              key={holder.holderId}
              value={(idx + 2).toString()}
              sx={{ display: 'flex', flex: 1 }}
            >
              <HolderAccount holderId={holder.holderId}
                             accessAccounts={holder.accessAccounts || []}
                             limitCards={holder.limitCards || limitCardsByArray || false}
                             onChange={handleChangeAccount}
                             disabled={false}
              />
            </TabPanel>
          );
        }
      })
    );
  }, [user.holders, handleChangeAccount]);


  const handleChanges = (holders: UserHoldersProps[], changedCriticalValue: boolean) => {
    setChangeCriticalValue(changedCriticalValue);
    setUser(prev => ({ ...prev, holders: [...holders] }));
  };

  const commonHolder = useMemo(() => {
    return dataHolders.user.holders.map(holder => ({ label: holder.name, value: holder.holderId })) as any;
  }, [dataHolders]);

  const [createOrUpdateUser, { isLoading }] = usersMutation.useCreateOrUpdateUsersMutation();


  const validationError = useCallback((name: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const field: any = errors[name];
    return ({ error: !!field, helperText: field?.message as any });
  }, [errors]);

  const { showModal } = useModal();

  const checkConfirmSave = () => {
    if (changedCriticalValue && personalAccountId === Number(getStorage<string>(Storage.userId))) {
      showModal({
        buttonType: ModalButton.OkCancel,
        children: `${t('module.setting.users.confirmChangeAdminRole')}`,
        callbackOk: handleBtnOk,
      });
    } else {
      handleBtnOk();
    }
  };

  const handleBtnOk = () => {

    try {
      const prepareUser = {
        ...user,
        commonHolderId: defaultHolderId,
        holders: user.holders && user.holders.map(holderAll => {

          /* Удаляем атрибуты */
          const { attributes, ...restHolder } = holderAll;
          const holder = restHolder;

          /* Проверяем возможно это было удаление фирмы пользователя */
          if (holder.apiRole === '-') {
            holder.isDeleted = !!dataUsers.user.holders.find((uHolder) => (uHolder.holderId === holder.holderId));
          } else {
            /* Если была поментка об удалении, то снимаем */
            if (holder.isDeleted) holder.isDeleted = false;
          }

          if (holder.limitAccounts === false) {
            return { ...holder, accessAccounts: [] };
          } else {

            /* Проверяем, если установлен флаг ограничение по счетам, должны быть выбраны счета */
            const isExistAccount = holder.accessAccounts && holder.accessAccounts.length > 0;

            /* Если нет ругаемся что необходимо выбрать счета */
            if (!isExistAccount && [Roles.Admin, Roles.Driver].indexOf(holder.apiRole as Roles) === -1) {
              toast({ type: 'error', message: t('error.1008') });
              throw new Error(t('error.1008'));
            }


            /* Если не установлен флаг ограничения по картам, тогда во все массивы добавляю []  */
            if (holder.limitCards === false) {

              holder.accessAccounts = holder.accessAccounts && holder.accessAccounts.map(account => ({
                ...account,
                accessCards: [],
              }));

            } else {
              /* Проверяем, если установлен флаг ограничение по картам, то должна быть выбрана хотя бы одна карта */

              const isExistCards = holder.accessAccounts && holder.accessAccounts.find(account => account.accessCards && account.accessCards.length > 0);

              /* Если нет ругаемся, что необходимо выбрать карту */
              if (!isExistCards && [Roles.Admin, Roles.Driver].indexOf(holder.apiRole as Roles) === -1) {
                toast({ type: 'error', message: t('error.1009') });
                throw new Error(t('error.1009'));
              }
            }
          }

          return { ...holder };
        }),
      };

      //console.log('prepareUser', prepareUser);

      createOrUpdateUser(prepareUser as any)
        .then(({ error }: any) => {
          const isError = !!error;
          transformResponseError({ isError, error: String(error) });
          !isError && handleClose();
        });

    } catch (errorMsg: any) {
      console.error('errorMsg', errorMsg.toString());
    }
  };

  const { config } = useAppSelector(state => state.layoutReducer);

  /**
   * Проверяем что это добавление нового пользователя и номер лтелефона введен
   */
  const [isBlockProfile, setBlockProfile] = useState(true);

  const isExistPhoneNumber = useMemo(() => {
    /* Если создание нового пользователя или включена блокировка */
    return personalAccountId === -1 && isBlockProfile;
  }, [isBlockProfile, personalAccountId]);

  const [getFindExistUser] = userProfileService.useLazyGetFindExistUserQuery();

  useEffect(() => {
    if (user.phoneNumber?.length === 12 && personalAccountId === -1) {
      const searchingUser = dataUsersCache.users.find(itemUser => itemUser.phoneNumber === user.phoneNumber);

      if (searchingUser) {
        setUser(searchingUser);
        setBlockProfile(searchingUser.isReadOnlyProfile);
      } else {
        getFindExistUser({ phoneNumber: user.phoneNumber })
          .then(({ data, isError }) => {
            if (!isError && data && data.user) {
              const { isReadOnlyProfile } = data.user;
              setValue('firstName', data.user.firstName);
              setValue('lastName', data.user.lastName);
              setUser({ ...initialUser, id: data.user.personalAccountUserId, ...data.user });

              /* Если разрешено редактировато профиль даем возможность*/
              setBlockProfile(isReadOnlyProfile);
            } else {
              setBlockProfile(false);
            }

          });
     }
    }
  }, [user.phoneNumber, personalAccountId]);

  const defaultHolderId = useMemo(() => {
    return user.commonHolderId && user.commonHolderId !== -1 && user.commonHolderId || commonHolder && commonHolder.length > 0 && commonHolder[0].value;
  }, [user, commonHolder]);


  return (
    <Modal
      handleBtnOk={handleSubmit(checkConfirmSave)}
      isOpen={isShow}
      handleBtnCancel={handleClose}
      sx={{ paddingBottom: 0 }}
      loading={isLoading}
    >
      <Box sx={{ width: '650px', height: '550px', '& .MuiTabPanel-root': { padding: 0 } }}>
        <TabContext value={tab}>
          <TabList
            onChange={handleChange}
            textColor='secondary'
            variant="scrollable"
            scrollButtons="auto"

            indicatorColor='secondary'
          >
            <Tab label='Користувач' value={'1'} />
            {config.accessControl && renderTabs}
          </TabList>
          <TabPanel sx={{ padding: 0, margin: '.2rem 0 0 0' }} value={'1'}>
            <ContainerColumn>
              {/* Данные пользователя */}
              <Grid item xs={12}>
                <Grid mb={1} display={'flex'}>
                  <InputPhone
                    name={'phoneNumber'}
                    sx={{ marginRight: '3px', '& .Mui-disabled': { backgroundColor: 'transparent !important' } }}
                    inputType={'filled'}
                    value={user.phoneNumber}
                    onChange={handleChangeData}
                    disabled={isDisabledEdit}
                    label={t('module.setting.users.titlePhone')}
                    inputProps={register('phoneNumber')}
                    {...validationError('phoneNumber')}
                  />
                  <InputFilled
                    name={'email'}
                    label={t('module.setting.users.titleEmail')}
                    onChange={handleChangeData}
                    value={user.email}
                    disabled={isDisabledEdit || isExistPhoneNumber}
                  />
                </Grid>

                <Grid display={'flex'}>
                  <Box sx={{ width: '100%', marginRight: '3px' }}>
                    <InputFilled
                      name={'lastName'}
                      label={t('module.setting.users.titleLastName')}
                      onChange={handleChangeData}
                      value={user.lastName}

                      disabled={isDisabledEdit || isExistPhoneNumber}
                      inputProps={register('lastName')}
                      {...validationError('lastName')}
                    />
                  </Box>
                  <InputFilled
                    name={'firstName'}
                    label={t('module.setting.users.titleFirstName')}
                    onChange={handleChangeData}
                    value={user.firstName}
                    disabled={isDisabledEdit || isExistPhoneNumber}
                    inputProps={register('firstName')}
                    {...validationError('firstName')}
                  />
                </Grid>


                <Grid display={'flex'} alignItems={'center'} mt={1}>
                  <Grid item xs={6}>
                    <InputCheckbox
                      name={'userCanEdit'}
                      label={t('module.setting.users.titleUserEdit')}
                      onChange={handleChangeData}
                      defaultChecked={user.userCanEdit}
                      disabled={isDisabledEdit || isExistPhoneNumber}
                      sx={{ margin: 0, '& .MuiButtonBase-root': { padding: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <OutlineBox label={t('module.setting.users.titleHolderDefault')} textPosition={'left'}
                                sx={{ padding: '0 .2rem .3rem .2rem' }}>
                      <SelectMini
                        name={'commonHolderId'}
                        value={defaultHolderId}
                        data={commonHolder}
                        onChange={handleChangeData}
                        enableNotSelected={false}
                        disabled={isDisabledEdit || isExistPhoneNumber}
                      />
                    </OutlineBox>
                  </Grid>
                </Grid>
              </Grid>

              {/* Холдеры */}
              <Box display={'flex'} flexDirection={'column'} flexWrap={'wrap'} height={'290px'} pt={2}
                   overflow={'auto'}>
                <HolderList holders={user.holders || []} onChange={handleChanges}
                            disabled={false} />
              </Box>


            </ContainerColumn>
          </TabPanel>
          {renderTabPanel}
        </TabContext>


      </Box>
    </Modal>
  );
});
